import { useReactable } from "@reactables/react";
import { useHistory } from "react-router-dom";
import { RxToggle } from "@jauntin/reactables";
import { Button } from "@jauntin/react-ui";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import { PromoCodeProp } from "Features/PromoCodes/Rx/RxPromoCode";
import { RxPromoCodeForm } from "Features/PromoCodes/Rx/RxPromoCodeForm";
import ModalConfirmation from "@basicare/common/src/Components/ModalConfirmation";
import { PROMO_CODE_PAGE, getUrl } from "Helpers/URLParser";
import PromoCodeService from "Services/PromoCodeService";
import API from "../../../Services/API";
import { getPromoCodePayload } from "Features/PromoCodes/Rx/Selectors/promoCode.selectors";
import { mapPromoCodeDetails } from "@basicare/common/src/Models/promoCode.model";
import PromoCodeForm from "./PromoCodeForm";

const EditPromoCode = ({ rxPromoCode }: { rxPromoCode: PromoCodeProp }) => {
  const history = useHistory();

  const [state, actions] = rxPromoCode;

  const {
    promoCode: { data: promoCodeDetails },
  } = state;

  const rxPromoCodeForm = useReactable(RxPromoCodeForm, {
    promoCodeService: new PromoCodeService(new API()),
    promoCodeForm: mapPromoCodeDetails(promoCodeDetails),
    redeems: promoCodeDetails.redeems,
  });

  const [formState] = rxPromoCodeForm;

  const [showConfirmModal, confirmModalToggle] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  if (!formState) return;

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Edit Promo Code</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={discardModalToggle.toggleOn}
            />
            <Button
              className="btn btn-primary px-4 mx-2 text-nowrap"
              onClick={confirmModalToggle.toggleOn}
              text="Save Promo Code"
              disabled={!formState.root.valid || !formState.root.dirty}
            />
          </div>
        </div>
      </div>
      <PromoCodeForm
        form={rxPromoCodeForm}
        promoCodeDetails={promoCodeDetails}
      />
      {showConfirmModal && (
        <ModalConfirmation
          message={`Update ${promoCodeDetails.code} ?`}
          cancelBtnText="Back to Edit"
          checkboxLabelText="Yes, I confirm that I would like to update this promo code."
          show={showConfirmModal}
          handleClose={confirmModalToggle.toggleOff}
          action={() =>
            actions.edit.send(getPromoCodePayload(formState.root.value))
          }
          isUpdating={state.editRequest.loading}
        />
      )}

      <ModalDiscardAddNew
        show={showDiscardModal}
        handleClose={discardModalToggle.toggleOff}
        confirmAction={() => {
          history.push(getUrl(PROMO_CODE_PAGE, promoCodeDetails.id));
        }}
      />

      <ModalUpdateError
        show={Boolean(state.editRequest.error)}
        text="There was an error updating the promo code. Please try again"
        handleCloseError={actions.edit.resetState}
      />
    </>
  );
};

export default EditPromoCode;
