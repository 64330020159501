import { from } from "rxjs";
import { map } from "rxjs/operators";
import { ActionMap, Reactable } from "@reactables/core";
import { FormBuilders, asyncValidators } from "@jauntin/reactables";
import {
  ControlModels,
  RxFormActions,
  CustomReducers,
} from "@reactables/forms";
import { Member } from "./Models/member.model";
import { MemberDetails } from "./Models/memberDetails.model";
import { member } from "./Configs/member.config";
import { memberFromMemberDetails } from "./Models/member.model";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { addDependentReducer } from "@basicare/common/src/Rx/Reducers/addDependent.reducer";
import {
  placesAutocompleteReducers,
  PlacesAutoCompleteActions,
} from "@basicare/common/src/Rx/Reducers/placesAutocomplete.reducer";
import MembershipService from "../../../Services/MembershipService";
import { MemberStatuses } from "@basicare/common/src/Constants/memberStatuses";
import { parse, isBefore, subMonths } from "date-fns";

export type MemberFormState = ControlModels.Form<Member>;

export type MemberFormActions = {
  addDependent: () => void;
} & PlacesAutoCompleteActions &
  RxFormActions &
  ActionMap;

const memberFormReducers: CustomReducers<
  {
    addDependent: () => void;
  } & PlacesAutoCompleteActions
> = {
  ...placesAutocompleteReducers(["primaryMember"]),
  addDependent: addDependentReducer,
};

export const RxMemberForm = (
  membershipService: MembershipService,
  memberDetails?: MemberDetails
) =>
  FormBuilders.build(
    member(memberDetails ? memberFromMemberDetails(memberDetails) : undefined),
    {
      name: "rxMemberForm",
      reducers: memberFormReducers,
      providers: {
        ...formProviders,
        asyncValidators: asyncValidators([
          {
            name: "uniqueRecuroNumber",
            resource: (value) =>
              from(
                membershipService.getIsUniqueRecuroSubscriberNumber(value)
              ).pipe(
                map(({ data }: { data: { unique: boolean } }) => data.unique)
              ),
          },
        ]),
        validators: {
          ...formProviders.validators,
          threeMonthsInThePast: (value: string) => {
            const date = parse(value, "MM/dd/yyyy", new Date());
            const status = memberDetails?.status;

            return {
              threeMonthsInThePast:
                ((status && status !== MemberStatuses.Active) || !status) &&
                isBefore(date, subMonths(new Date(), 3)),
            };
          },
        },
      },
    }
  ) as Reactable<MemberFormState, MemberFormActions>;
