import React from "react";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";
import { facilityPropType } from "../../../Helpers/FacilityModel";
import { DEFAULT_FACILITY_CODE } from "../../../constants";

const Header = ({
  editing,
  handleShowDiscard,
  handleShowUpdate,
  setEditing,
  editReset,
  pristine,
  valid,
  hasCheckedFacilityCode,
  validFacilityCode,
  facility,
  setShowUpload,
}) => (
  <div className="content__header col-auto">
    <div className="d-flex justify-content-between align-items-center">
      <h4 className="m-0 font-weight-bold">Organization Overview</h4>
      <div className="d-flex float-right">
        {editing ? (
          <>
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={handleShowDiscard}
            />
            <Button
              text="Save Organization"
              className="btn btn-primary px-4 ml-2 text-nowrap"
              onClick={handleShowUpdate}
              disabled={
                pristine ||
                !valid ||
                !hasCheckedFacilityCode ||
                !validFacilityCode
              }
            />
          </>
        ) : (
          <>
            <Button
              text="Upload members by XLSX"
              onClick={() => setShowUpload(true)}
              className="btn btn-outline-secondary px-4 ml-2 text-nowrap"
            />
            {facility.code !== DEFAULT_FACILITY_CODE && (
              <Button
                text="Edit Organization"
                className="btn btn-primary px-4 ml-2 text-nowrap"
                onClick={() => {
                  editReset();
                  setEditing(true);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  </div>
);

Header.propTypes = {
  editing: PropTypes.bool.isRequired,
  handleShowDiscard: PropTypes.func.isRequired,
  handleShowUpdate: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  hasCheckedFacilityCode: PropTypes.bool.isRequired,
  validFacilityCode: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
  setShowUpload: PropTypes.func.isRequired,
};

export default Header;
