const getBottomBorderWidth = (state) => {
  if (state.menuIsOpen) {
    return "0";
  }
  if (state.isFocused) {
    return "2px";
  }
  return "1px";
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "none" },
    minHeight: 48,
    boxShadow: "none",
    width: "100%",
    border:
      state.isFocused || state.menuIsOpen
        ? "2px solid #2c2a5f"
        : "1px solid hsl(0, 0%, 80%)",
    borderBottomWidth: getBottomBorderWidth(state),
    borderBottomLeftRadius: state.menuIsOpen ? "0" : "4px",
    borderBottomRightRadius: state.menuIsOpen ? "0" : "4px",
    transition: "0 all",
  }),
  singleValue: (base) => ({
    ...base,
    position: "static",
    transform: "none",
    maxWidth: "100%",
  }),
  placeholder: (base) => ({
    ...base,
    position: "static",
    transform: "none",
    maxWidth: "100%",
    fontStyle: "italic",
    color: "#a2a3a5",
  }),
  indicatorSeparator: (base) => ({ ...base, display: "none" }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    transition: ".2s transform",
    color: "#2c2a5f",
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: state.isSelected ? "1px solid #bae1e3" : "none",
    borderBottom: state.isSelected ? "1px solid #bae1e3" : "none",
    color: state.isSelected ? "#2c2a5f" : "#484848",
    display: state.isDisabled ? "none" : "block",
    "&:hover": {
      backgroundColor: "#eaf2f8",
      "&:last-child": {
        borderRadius: "4px",
      },
    },
    backgroundColor:
      (state.isSelected && "#eeeeee") ||
      (state.isFocused && "#eaf2f8") ||
      provided.backgroundColor,
    whiteSpace: "nowrap",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 50,
    marginTop: "0",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    border: "2px solid #2c2a5f",
    borderTop: "none",
    boxShadow: "none",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "-48px",
      left: "-2px",
      right: "-2px",
      bottom: "-2px",
      borderRadius: "4px",
      zIndex: "-1",
      boxShadow:
        "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      zIndex: "-1",
      borderTop: "1px solid hsl(0deg 0% 0% / 10%)",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
};

export default customStyles;
