import { combineLatest, from, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Reactable, ofTypes } from "@reactables/core";
import { RxRequest, RequestState, RequestActions } from "@jauntin/reactables";
import {
  RxPromoCodeForm,
  PromoCodeFormState,
  PromoCodeFormActions,
} from "./RxPromoCodeForm";
import PromoCodeService from "Services/PromoCodeService";
import { PromoCodeForm } from "@basicare/common/src/Models/promoCode.model";
import { getPromoCodePayload } from "./Selectors/promoCode.selectors";

export type AddPromoCodeActions = {
  form: PromoCodeFormActions;
  generatePromoCode: RequestActions<undefined>;
  submission: RequestActions<PromoCodeForm>;
};

export interface AddPromoCodeState {
  form: PromoCodeFormState;
  generatePromoCodeRequest: RequestState<string>;
  submission: RequestState<unknown>;
}

export const RxAddPromoCode = ({
  promoCodeService,
  onAddPromoCodeSuccess,
}: {
  promoCodeService: PromoCodeService;
  onAddPromoCodeSuccess?: () => void;
}): Reactable<AddPromoCodeState, AddPromoCodeActions> => {
  const [
    generatePromoCodeRequest$,
    generatePromoCode,
    generagePromoCodeActions$,
  ] = RxRequest<undefined, string>({
    name: "rxAddPromoCodeRequest",
    effect: (action$) =>
      action$.pipe(
        map(() => {
          return from(promoCodeService.getGeneratedPromoCode()).pipe(
            map(({ data: { code } }) => code)
          ) as Observable<string>;
        })
      ),
  });

  const promoCodeGenerated$ = generagePromoCodeActions$.pipe(
    ofTypes(["sendSuccess"]),
    map(({ payload }) => ({ type: "promoCodeGenerated", payload }))
  );

  const [form$, formActions] = RxPromoCodeForm({
    promoCodeService,
    sources: [promoCodeGenerated$],
  });

  const [submission$, submissionActions] = RxRequest<PromoCodeForm, unknown>({
    name: "rxAddPromoCodeRequest",
    effect: (action$) =>
      action$.pipe(
        map(({ payload }) => {
          return from(
            promoCodeService.postAddNewPromoCode(getPromoCodePayload(payload))
          ).pipe(
            tap(() => {
              onAddPromoCodeSuccess && onAddPromoCodeSuccess();
            })
          );
        })
      ),
  });

  const state$ = combineLatest({
    form: form$,
    generatePromoCodeRequest: generatePromoCodeRequest$,
    submission: submission$,
  });

  const actions = {
    form: formActions,
    generatePromoCode,
    submission: submissionActions,
  };

  return [state$, actions];
};
