import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SearchFacilitiesContainer from "./SearchFacilitiesContainer";
import {
  emptyFacility,
  facilityPropType,
} from "../../../Helpers/FacilityModel";
import LoadFacilityContainer from "./LoadFacilityContainer";
import FacilitiesMenu from "./FacilitiesMenu";
import FacilityContainer from "./FacilityContainer";
import FacilityNotesContainer from "./FacilityNotesContainer";
import AddNewFacilityContainer from "./AddNewFacilityContainer";
import {
  getUrl,
  FACILITIES_PAGE,
  FACILITY_PAGE,
  FACILITY_NOTES_PAGE,
  ADD_FACILITY_PAGE,
} from "../../../Helpers/URLParser";

const Facilities = ({ facility }) => {
  return (
    <>
      {/* LoadFacilityContainer will load the current facility when it is mounted */}
      <Switch>
        <Route
          exact
          component={SearchFacilitiesContainer}
          path={getUrl(FACILITIES_PAGE)}
        />
        <Route
          path={getUrl(ADD_FACILITY_PAGE)}
          component={AddNewFacilityContainer}
        />
        <LoadFacilityContainer>
          <div className="subsection__wrapper">
            <FacilitiesMenu id={facility.id || 0} />
            <div className="subsection__container">
              <div>
                <Route
                  exact
                  path={getUrl(FACILITY_PAGE)}
                  component={FacilityContainer}
                />
                <Route
                  exact
                  path={getUrl(FACILITY_NOTES_PAGE)}
                  component={FacilityNotesContainer}
                />
              </div>
            </div>
          </div>
        </LoadFacilityContainer>
      </Switch>
    </>
  );
};

Facilities.propTypes = {
  facility: facilityPropType,
};

Facilities.defaultProps = {
  facility: emptyFacility,
};

const mapStateToProps = (state) => ({
  facility: state.facilities.currentFacility,
});

const FacilitiesContainer = connect(mapStateToProps)(Facilities);

export default FacilitiesContainer;
