import React, { useCallback, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { reportsForm } from "../../../constants";
import StyledDateRangePickerField from "@basicare/common/src/Components/Event/DateRangePickerField/StyledDateRangePickerField";
import ReportTypeField from "./FormElements/ReportTypeField";
import { requiredDates } from "@basicare/common/src/Helpers/validators";
import { required } from "../../../Helpers/validators";
import { downloadMembershipReport } from "../../../Helpers/Reports";

const MemberReportByType = () => {
  const [fetching, setFetching] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const { startDate, endDate } = dateRange;
  const timezone = useSelector((state) => state.app.timezone);
  const [type, setType] = useState("APSMembershipReport");

  const disabledDates = useCallback(
    (day) => (focusedInput) => focusedInput > new Date(),
    []
  );

  return (
    <div>
      <strong>Members Added/Updated Report</strong>
      <p>Generates file drop of the selected type</p>
      <div className="row">
        <div className="col-md-6">
          <div className="mt-3">
            <Field
              name="dateRange"
              component={StyledDateRangePickerField}
              input={{
                value: dateRange,
                onChange: setDateRange,
              }}
              validate={requiredDates}
              inputClassName="form-control-lg"
              disabledDates={disabledDates}
            />

            <Field
              name="type"
              component={ReportTypeField}
              validate={[required]}
              onChange={(e) => setType(e.target.value)}
            />
          </div>

          <div className="mt-4">
            <button
              className="btn btn-primary py-2"
              disabled={!startDate || !endDate || fetching}
              onClick={() => {
                setFetching(true);
                downloadMembershipReport(
                  startDate,
                  endDate,
                  timezone,
                  type
                ).then(() => setFetching(false));
              }}
            >
              {fetching && (
                <span
                  className="policy__download spinner-border spinner-border-sm align-middle mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Download Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(),
  reduxForm({
    form: reportsForm,
  })
)(MemberReportByType);
