import { group, array, control } from "@reactables/forms";
import { Member, emptyMember } from "../Models/member.model";
import { primaryMember as primaryMemberConfig } from "@basicare/common/src/Rx/Configs/primaryMember.config";
import { dependent } from "@basicare/common/src/Rx/Configs/dependent.config";

export const member = ({
  primaryMember,
  dependents,
  membership: {
    recuroSubscriberNumber,
    effectiveDate,
    organization,
    cancellationDate = "",
  },
}: Member = emptyMember) =>
  group({
    controls: {
      membership: group({
        validators: ["recuroNumRequiredForOrg"],
        controls: {
          recuroSubscriberNumber: control({
            initialValue: recuroSubscriberNumber,
            asyncValidators: ["uniqueRecuroNumber"],
            validators: ["digits9orAlphaNum11"],
            normalizers: ["normalize11AlphaNums"],
          }),
          effectiveDate: control({
            initialValue: effectiveDate,
            validators: ["required", "effectiveDate", "threeMonthsInThePast"],
            normalizers: ["normalizeDate"],
          }),
          cancellationDate: control({
            initialValue: cancellationDate,
            validators: ["validDateFormat"],
            normalizers: ["normalizeDate"],
          }),
          organization: control([organization, ["required"]]),
        },
      }),
      primaryMember: primaryMemberConfig(primaryMember),
      dependents: array({
        controls: dependents.map((d) => dependent(d)),
      }),
    },
  });
