import { Action } from "@reactables/core";
import { from, of, Observable } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { Reducers, RxBuilder } from "@jauntin/reactables";

import MembershipService from "../../../Services/MembershipService";
import { CustomContact, MemberDetails } from "./Models/memberDetails.model";

const loadMemberReducer = (
  membershipService: MembershipService,
  id: number
) => ({
  reducer: Reducers.load,
  effects: [
    (action$) =>
      action$.pipe(
        mergeMap(() => {
          return from(membershipService.getMembership(id)).pipe(
            map(({ data }: { data: MemberDetails }) => ({
              type: "loadMemberSuccess",
              payload: data,
            })),
            catchError((error) =>
              of({
                type: "loadMemberFailure",
                payload: error,
              })
            )
          );
        })
      ),
  ],
});

export const RxLoadMember = ({
  membershipService,
  id,
  sources = [],
}: {
  membershipService: MembershipService;
  id: number;
  sources: Observable<Action<unknown>>[];
}) => {
  const loadMember = loadMemberReducer(membershipService, id);

  return RxBuilder({
    name: "rxLoadMember",
    initialState: Reducers.loadableInitialState,
    sources: [of({ type: "loadMember" }), ...sources],
    reducers: {
      loadMember,
      saveMemberSuccess: loadMember,
      sendSuccessAddNote: loadMember,
      cancelMemberSuccess: loadMember,
      sendContactsAndEmailsSuccess: (
        state: Reducers.LoadableState<MemberDetails>,
        {
          payload: { updatedContacts },
        }: Action<{ updatedContacts: CustomContact[] }>
      ) => {
        return {
          ...state,
          data: {
            ...state.data,
            contacts: updatedContacts,
          },
        };
      },
      loadMemberSuccess: Reducers.loadSuccess,
      loadMemberFailure: Reducers.loadError,
    },
  });
};
