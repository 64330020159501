import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { CheckboxInput } from "@jauntin/react-ui";
import { producerContactsPropType } from "../../../../constants";

const ProducerContacts = ({ fields, producerContacts }) => (
  <div className="form-group mb-4">
    <div className="mb-3">
      <strong>Who should receive emails containing member information?</strong>
    </div>
    {fields.map((contact, i) => (
      <Field
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        component={CheckboxInput}
        name={`${contact}.copyOnEmails`}
        type="checkbox"
        contactInfo={producerContacts[i]}
        ariaLabel="Copy on emails checkbox"
        className="d-flex align-items-center mb-2"
        label={
          <>
            {producerContacts[i].name && (
              <span className="mr-3">{producerContacts[i].name}</span>
            )}
            <p className="font-weight-normal mb-0">
              {producerContacts[i].email}
            </p>
          </>
        }
        labelClassName="pl-2 contacts__copyOnEmail"
      />
    ))}
  </div>
);
ProducerContacts.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  producerContacts: PropTypes.arrayOf(producerContactsPropType).isRequired,
};

export default ProducerContacts;
