import {
  FieldInputProps,
  FieldMetaProps,
} from "@basicare/common/src/Constants/ReduxFormPropTypes";
import PropTypes from "prop-types";
import { ACTIVE } from "../../../../constants";

const codes = [33153, 35760, 37450, 40100, 40332, 43432, 43511, 43542, 111115];

const ProductCodeField = ({ input, meta, status }) => (
  <div className="form-group">
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Product Code
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <select
      {...input}
      name={input.name}
      id={input.name}
      className="custom-select custom-select-lg"
      required
      disabled={status === ACTIVE}
    >
      <option value="">Select...</option>
      {codes.map((code) => (
        <option key={code} value={code}>
          {String(code)}
        </option>
      ))}
    </select>
  </div>
);

ProductCodeField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  status: PropTypes.string,
};

export default ProductCodeField;
