import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import { producerContactsPropType } from "../constants";
import notePropType from "./NoteModel";

/**
 *
 * Model used to represent a `Facility`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyFacility = {
  id: 0,
  code: "",
  productCode: "",
  recuroAgentId: "",
  hideFacilityInfo: false,
  producerId: 0,
  producerName: "",
  producerCommissionId: "",
  producer: {
    producerContacts: [],
  },
  facilityProducerContacts: [],
  states: [],
  createdAt: "",
  updatedAt: "",
  status: "",
  facilityContacts: [],
  otherAdditionalInsured: {
    id: 0,
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  },
  notes: [],
  referralLink: "",
  logoUrl: null,
  apsPlanId: "",
  apsGroupId: "",
  elixirGroupId: "",
  recuroGroupId: "",
  requiresRecuroSubscriberNumber: false,
  doNotSendEmailsToMember: false,
  monthlyPrice: "",
};

/**
 *
 * PROP TYPES
 *
 */
const additionalInsuredPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
});
const facilityContactsPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  copyOnEmails: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
});
const facilityNotesPropType = notePropType;
const facilityPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  productCode: PropTypes.string.isRequired,
  recuroAgentId: PropTypes.string,
  hideFacilityInfo: PropTypes.bool.isRequired,
  producerId: PropTypes.number.isRequired,
  producerName: PropTypes.string.isRequired,
  producerCommissionId: PropTypes.string.isRequired,
  emailProducerContacts: PropTypes.arrayOf(producerContactsPropType),
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  facilityContacts: PropTypes.arrayOf(facilityContactsPropType),
  otherAdditionalInsured: additionalInsuredPropType,
  notes: PropTypes.arrayOf(facilityNotesPropType),
  logoUrl: PropTypes.string,
  apsPlanId: PropTypes.string.isRequired,
  apsGroupId: PropTypes.string.isRequired,
  elixirGroupId: PropTypes.string.isRequired,
  recuroGroupId: PropTypes.string.isRequired,
  requiresRecuroSubscriberNumber: PropTypes.bool.isRequired,
  doNotSendEmailsToMember: PropTypes.bool.isRequired,
  monthlyPrice: PropTypes.string.isRequired,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerFacilityToFacilityModel = (facility) => {
  const model = cloneDeep(emptyFacility);
  model.id = facility.id;
  model.code = facility.code;
  model.name = facility.name;
  model.productCode = facility.productCode;
  model.recuroAgentId = facility.recuroAgentId;
  model.hideFacilityInfo = facility.hideFacilityInfo;
  model.producerId = facility.producer.id;
  model.producerName = facility.producer.name;
  model.producerCommissionId = facility.producer.commissionId;
  model.states = facility.states;
  model.createdAt = facility.createdAt;
  model.updatedAt = facility.updatedAt;
  model.status = facility.status;
  model.logoUrl = facility.logoUrl;
  model.apsPlanId = facility.apsPlanId;
  model.apsGroupId = facility.apsGroupId;
  model.elixirGroupId = facility.elixirGroupId;
  model.recuroGroupId = facility.recuroGroupId;
  model.requiresRecuroSubscriberNumber = Boolean(
    facility.requiresRecuroSubscriberNumber
  );
  model.doNotSendEmailsToMember = Boolean(facility.doNotSendEmailsToMember);
  model.monthlyPrice = facility.monthlyPrice;

  model.facilityProducerContacts = facility.facilityProducerContacts.map(
    (m) => ({
      producerContactId: m.id,
      fullName: m.fullName || "",
      email: m.email,
    })
  );

  if (
    "contacts" in facility.producer &&
    "facilityProducerContacts" in facility
  ) {
    let producerContacts = [];
    producerContacts = facility.producer.contacts.map((m) => ({
      producerContactId: m.id,
      fullName: m.fullName || "",
      email: m.email,
    }));

    model.producer.producerContacts = producerContacts.map(
      (producerContact) => ({
        ...producerContact,
        copyOnEmails: facility.facilityProducerContacts.some(
          (pc) => producerContact.producerContactId === pc.id
        ),
      })
    );
  }

  model.otherAdditionalInsured = {
    ...model.otherAdditionalInsured,
    ...(facility.additionalInsuredAddress || {}),
  };

  model.facilityContacts = facility.contacts.map((m) => ({
    id: m.id,
    fullName: m.fullName || "",
    role: m.role || "",
    copyOnEmails: m.copyOnEmails,
    email: m.email,
  }));

  model.notes = facility.notes.map((m) => ({
    id: m.id,
    type: m.type,
    message: m.message,
    userName: m.user?.name || "",
    createdAt: m.createdAt,
  }));

  model.referralLink = (
    facility.links || { facilityReferralLink: "" }
  ).facilityReferralLink;
  return model;
};

const editFacilityInitialFormValues = (facility, otherInsured) => ({
  facilityName: facility.name,
  facilityCode: facility.code,
  facilityStatus: facility.status,
  hideFacilityInfo: facility.hideFacilityInfo,
  producer: {
    value: facility.producerId,
    label: `${facility.producerName} — ${facility.producerCommissionId}${
      facility.subProducerCode ? ` - ${facility.subProducerCode}` : ""
    }`,
    producerContacts: facility.producer.producerContacts,
  },
  otherInsured: {
    address1: otherInsured.address1,
    address2: otherInsured.address2,
    city: otherInsured.city,
    state: otherInsured.state,
    zip: otherInsured.zip,
    country: otherInsured.country,
  },
  contacts: facility.facilityContacts,
  facilityLogoUrl: facility.logoUrl || null,
  facilityImgFile: null,
  productCode: facility.productCode,
  recuroAgentId: facility.recuroAgentId,
  apsPlanId: facility.apsPlanId,
  apsGroupId: facility.apsGroupId,
  elixirGroupId: facility.elixirGroupId,
  recuroGroupId: facility.recuroGroupId,
  requiresRecuroSubscriberNumber: Boolean(
    facility.requiresRecuroSubscriberNumber
  ),
  doNotSendEmailsToMember: Boolean(facility.doNotSendEmailsToMember),
  monthlyPrice: facility.monthlyPrice,
});

const initialFacilityFormValues = () => cloneDeep(emptyFacility);

export {
  emptyFacility,
  facilityPropType,
  additionalInsuredPropType,
  convertServerFacilityToFacilityModel,
  editFacilityInitialFormValues,
  initialFacilityFormValues,
};
