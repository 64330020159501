import React from "react";
import DailyMembershipReport from "./DailyMembershipReport";
import MemberReportByType from "./MemberReportByType";
import AllMembersReport from "./AllMembersReport";
import ElixirMembersReport from "./ElixirMembersReport";

const ReportsContainer = () => {
  return (
    <>
      <div className="p-5 scroll-part">
        <h1 className="mb-5">Reports</h1>
        <ul className="list-unstyled mt-5">
          <li className="mb-5">
            <DailyMembershipReport />
          </li>
          <li className="mb-5">
            <MemberReportByType />
          </li>
          <li className="mb-5">
            <ElixirMembersReport />
          </li>
          <li className="mb-5">
            <AllMembersReport />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ReportsContainer;
