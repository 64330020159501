import { AddMemberState } from "../RxAddMember";
import { RelationshipType } from "@basicare/common/src/Constants/dependents";
import {
  AddMemberPayload,
  Member,
  SaveMemberPayload,
} from "../Models/member.model";
import { MembershipSource } from "@basicare/common/src/Constants/membership";
import { getHasVisibleDependentDobError } from "@basicare/common/src/Rx/Selectors/member.selector";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";

export const getIsSpouseSelected = (state: AddMemberState) =>
  state.form.root.value.dependents.some(
    (dependent) =>
      dependent.relationshipToAccountHolder === RelationshipType.Spouse
  );

export const getSaveMemberPayload = ({
  membership: { effectiveDate, organization, recuroSubscriberNumber },
  dependents,
  primaryMember: {
    firstName,
    lastName,
    gender,
    dateOfBirth,
    phoneNumber: phone,
    email,
    ...address
  },
}: Member): SaveMemberPayload => ({
  effectiveDate,
  organizationId: organization.id,
  recuroSubscriberNumber,
  firstName,
  lastName,
  gender,
  dateOfBirth,
  primaryAddress: {
    address1: address.address,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zip: address.zip,
    country: "US",
  },
  phone,
  email,
  dependents,
});

export const getAddMemberPayload = ({
  form,
}: AddMemberState): AddMemberPayload => ({
  ...getSaveMemberPayload(form.root.value),
  source: MembershipSource.ADMIN_CREATE_MANUAL,
});

export const getShowAddMemberActionBtn = (
  state: AddMemberState,
  submitTouched: boolean
) => {
  const hasVisibleRecuroNumRequiredForOrgError =
    state.form["membership"].errors.recuroNumRequiredForOrg &&
    state.form["membership.recuroSubscriberNumber"].touched;

  const visibleTouchError = hasControlTouchError(state.form);

  const hasVisibleDependentDobError = getHasVisibleDependentDobError(state);

  return (
    state.form.root.valid ||
    visibleTouchError ||
    hasVisibleRecuroNumRequiredForOrgError ||
    hasVisibleDependentDobError ||
    submitTouched
  );
};
