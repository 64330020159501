import PropTypes from "prop-types";

/**
 *
 * PROP TYPES
 *
 */
const membershipPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  subscriberNumber: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  effectiveDate: PropTypes.string.isRequired,
  cancellationDate: PropTypes.string,
});

export { membershipPropType };
