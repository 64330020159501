import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  reduxForm,
  reset,
  initialize,
  getFormValues,
  formValueSelector,
} from "redux-form";
import { compose } from "redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import {
  alertUpdated,
  emailProducerContactsPropType,
  facilityFormName,
  modalUpdateServerErrorMessage,
  producersPropType,
  statePropType,
} from "../../../constants";
import Header from "./Header";
import ModalDiscard from "./ModalDiscard";
import ModalUpdate from "./ModalUpdate";
import API from "../../../Services/API";
import {
  checkIsValidFacilityCode,
  errorResponse,
  getFacility,
  searchProducersForFacility,
  setEditingAction,
  setNewFacilityStatusMessage,
  setSelectedProducerInResults,
  showFacilityStatusAlert,
  logout as logoutAction,
} from "../../../Redux/Actions/actions";
import {
  facilityPropType,
  additionalInsuredPropType,
  editFacilityInitialFormValues,
} from "../../../Helpers/FacilityModel";
import FacilityService from "../../../Services/FacilityService";
import FacilityStatus from "./FacilityStatus";
import Information from "./Information";
import FacilityProducerContact from "./FacilityProducerContact";
import Contact from "./Contact";
import Debounce from "../../../Helpers/Debounce";
import ModalUpdateError from "../../Shared/Components/ModalUpdateError";
import AlertMessage from "../../Shared/Components/AlertMessage";
import RouteLeavingEditGuard from "../../Shared/Components/RouteLeavingEditGuard";
import { getUrl, LOGIN_PAGE } from "../../../Helpers/URLParser";
import { contactEmailsUnique } from "../../../Helpers/validators";
import { fileToBase64 } from "@basicare/common/src/Helpers/Base64EncodeFile";
import ModalUpload from "./ModalUpload";

const debouncer = new Debounce({ period: 500 });
const allFormValues = getFormValues(facilityFormName);
const formValues = formValueSelector(facilityFormName);

const Facility = ({
  editReset,
  facility,
  otherInsured,
  states,
  update,
  pristine,
  valid,
  editing,
  setEditing,
  checkAndSetValidFacilityCode,
  getProducers,
  producersList,
  setSelectedProducerResult,
  showAlert,
  setShowAlert,
  newFacilityStatusMessage,
  resetNewFacilityStatusMessage,
  producerContacts,
  facilityProducerContacts,
  commissionRateOptions,
  navToPath,
  logout,
  facilityCode,
  requiresRecuroSubscriberNumber,
}) => {
  const [showModalDiscard, setShowModalDiscard] = useState(false);
  const handleCloseDiscard = () => setShowModalDiscard(false);
  const handleShowDiscard = () => setShowModalDiscard(true);

  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleCloseUpdate = () => setShowModalUpdate(false);
  const handleShowUpdate = () => setShowModalUpdate(true);

  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);

  const [validFacilityCode, setValidFacilityCode] = useState(true);
  const [hasCheckedFacilityCode, setHasCheckedFacilityCode] = useState(true);
  const [leaveMidEditNextNavPath, setLeaveMidEditNextNavPath] = useState("");
  const [showUpload, setShowUpload] = useState(false);

  return (
    <>
      <Header
        editing={editing}
        handleShowDiscard={handleShowDiscard}
        handleShowUpdate={handleShowUpdate}
        setEditing={setEditing}
        editReset={editReset}
        pristine={pristine}
        valid={valid}
        hasCheckedFacilityCode={hasCheckedFacilityCode}
        validFacilityCode={validFacilityCode}
        facility={facility}
        setShowUpload={setShowUpload}
      />
      <div className="content__body">
        <AlertMessage
          show={showAlert}
          setShowAlert={setShowAlert}
          newStatusMessage={newFacilityStatusMessage}
          resetNewStatusMessage={resetNewFacilityStatusMessage}
        />
        <FacilityStatus
          editing={editing}
          facility={facility}
          requiresRecuroSubscriberNumber={requiresRecuroSubscriberNumber}
        />
        <Information
          editing={editing}
          facility={facility}
          validFacilityCode={validFacilityCode}
          setValidFacilityCode={setValidFacilityCode}
          hasCheckedFacilityCode={hasCheckedFacilityCode}
          setHasCheckedFacilityCode={setHasCheckedFacilityCode}
          checkAndSetValidFacilityCode={checkAndSetValidFacilityCode}
          otherInsured={otherInsured}
          states={states}
          facilityCodeCounter={facilityCode.length}
        />
        <FacilityProducerContact
          editing={editing}
          facility={facility}
          getProducers={getProducers}
          producersList={producersList}
          setSelectedProducerResult={setSelectedProducerResult}
          producerContacts={producerContacts}
          facilityProducerContacts={facilityProducerContacts}
          commissionRateOptions={commissionRateOptions}
        />
        <Contact editing={editing} facility={facility} />
      </div>
      <ModalDiscard
        show={showModalDiscard}
        handleClose={handleCloseDiscard}
        setPath={setLeaveMidEditNextNavPath}
        action={() => {
          setEditing(false);
          editReset();
          if (leaveMidEditNextNavPath === getUrl(LOGIN_PAGE)) {
            logout();
          }
          if (leaveMidEditNextNavPath) {
            navToPath(leaveMidEditNextNavPath);
          }
        }}
      />
      <ModalUpdate
        show={showModalUpdate}
        action={() => update(handleCloseUpdate, handleShowError, setEditing)}
        handleClose={handleCloseUpdate}
      />
      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />

      <ModalUpload
        show={showUpload}
        action={() => {}}
        handleClose={() => setShowUpload(false)}
        facilityId={facility.id}
      />

      {/* Route guard to prompt user with modal when trying to navigate away while in edit mode */}
      <RouteLeavingEditGuard
        when={editing || !!leaveMidEditNextNavPath}
        path={leaveMidEditNextNavPath}
        showDiscard={handleShowDiscard}
        setPath={setLeaveMidEditNextNavPath}
      />
    </>
  );
};

Facility.propTypes = {
  editReset: PropTypes.func.isRequired,
  facility: facilityPropType.isRequired,
  otherInsured: additionalInsuredPropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  update: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  checkAndSetValidFacilityCode: PropTypes.func.isRequired,
  getProducers: PropTypes.func.isRequired,
  producersList: PropTypes.arrayOf(producersPropType),
  setSelectedProducerResult: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  newFacilityStatusMessage: PropTypes.string.isRequired,
  resetNewFacilityStatusMessage: PropTypes.func.isRequired,
  producerContacts: PropTypes.arrayOf(emailProducerContactsPropType).isRequired,
  facilityProducerContacts: PropTypes.arrayOf(emailProducerContactsPropType)
    .isRequired,
  commissionRateOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  navToPath: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

Facility.defaultProps = {
  producersList: [],
};

const mapStateToProps = (state) => ({
  facility: state.facilities.currentFacility,
  otherInsured: state.facilities.currentFacility.otherAdditionalInsured,
  states: state.app.states,
  editing: state.facilities.editing,
  producersList: state.facilities.producersSearchResults,
  contacts: state.form.facility.values
    ? state.form.facility.values.contacts
    : [],
  showAlert: state.facilities.newFacilityStatus,
  newFacilityStatusMessage: state.facilities.newFacilityStatusMessage,
  producerContacts:
    state.form.facility.values &&
    state.form.facility.values.producer &&
    state.form.facility.values.producer.producerContacts
      ? state.form.facility.values.producer.producerContacts
      : [],
  facilityProducerContacts: state.facilities.currentFacility.producer
    ? state.facilities.currentFacility.facilityProducerContacts
    : [],
  commissionRateOptions: state.facilities.commissionRates,
  facilityCode:
    formValues(state, "facilityCode") || state.facilities.currentFacility.code,
  requiresRecuroSubscriberNumber:
    formValues(state, "requiresRecuroSubscriberNumber") !== undefined
      ? formValues(state, "requiresRecuroSubscriberNumber")
      : state.facilities.currentFacility.requiresRecuroSubscriberNumber,
});

const mapDispatchToProps = (dispatch) => ({
  editReset: (facility) => {
    const otherInsured = facility.otherAdditionalInsured;
    dispatch(reset(facilityFormName));
    dispatch(
      initialize(
        facilityFormName,
        editFacilityInitialFormValues(facility, otherInsured)
      )
    );
    dispatch(
      setSelectedProducerInResults({
        value: facility.producerId,
        label: `${facility.producerName} — ${facility.producerCommissionId}${
          facility.subProducerCode ? ` - ${facility.subProducerCode}` : ""
        }`,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
  navToPath: (path) => dispatch(ConnectedRouter.push(path)),
  logout: () => dispatch(logoutAction()),
  checkAndSetValidFacilityCode: (
    id,
    setHasCheckedFacilityCode,
    setValidFacilityCode
  ) =>
    dispatch(
      checkIsValidFacilityCode(
        id,
        setHasCheckedFacilityCode,
        setValidFacilityCode
      )
    ),
  setSelectedProducerResult: (producer) => {
    dispatch(setSelectedProducerInResults(producer));
  },
  getProducers: (inputValue) =>
    debouncer.do(
      (searchInput) => dispatch(searchProducersForFacility(searchInput)),
      inputValue
    ),
  update: (handleCloseModal, handleShowError, setEditing) => {
    dispatch((_, getState) => {
      const state = getState();
      const facility = state.facilities.currentFacility;
      const { id } = facility;
      let data = [];
      let values = [];

      values = allFormValues(getState());
      const emailProducerContacts = values.producer.producerContacts.filter(
        (contact) => contact.copyOnEmails
      );
      data = {
        id,
        name: values.facilityName,
        status: values.facilityStatus,
        code: values.facilityCode,
        productCode: values.productCode,
        recuroAgentId: values.recuroAgentId,
        hideFacilityInfo: values.hideFacilityInfo,
        otherInsured: {
          id: facility.otherAdditionalInsured.id,
          ...values.otherInsured,
        },
        producerId: values.producer.value,
        emailProducerContacts,
        contacts: values.contacts,
        logoUrl: values.facilityLogoUrl ? "notChanged" : null,
        apsPlanId: values.apsPlanId,
        apsGroupId: values.apsGroupId,
        elixirGroupId: values.elixirGroupId,
        recuroGroupId: values.recuroGroupId,
        requiresRecuroSubscriberNumber: values.requiresRecuroSubscriberNumber,
        doNotSendEmailsToMember: values.doNotSendEmailsToMember,
        monthlyPrice: values.monthlyPrice,
      };
      let updateFacilityPromise;

      if (values.facilityImgFile) {
        updateFacilityPromise = fileToBase64(values.facilityImgFile).then(
          (content) => {
            const logoImageFile = {
              fileName: values.facilityImgFile.name,
              size: values.facilityImgFile.size,
              content,
            };
            return new FacilityService(new API()).putFacilityEditDetails({
              ...data,
              logoImageFile,
            });
          }
        );
      } else {
        updateFacilityPromise = new FacilityService(
          new API()
        ).putFacilityEditDetails(data);
      }

      return updateFacilityPromise
        .then((response) => {
          if (response.status === 204) {
            handleCloseModal();
            dispatch(getFacility(id));
            dispatch(showFacilityStatusAlert(true));
            dispatch(setNewFacilityStatusMessage(alertUpdated("Organization")));
            setEditing(false);
          }
        })
        .catch((err) => {
          handleCloseModal();
          handleShowError();
          dispatch(errorResponse(err));
        });
    });
  },
  setShowAlert: (val) => dispatch(showFacilityStatusAlert(val)),
  resetNewFacilityStatusMessage: () =>
    dispatch(setNewFacilityStatusMessage("")),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  editReset: () => dispatchProps.editReset(stateProps.facility),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: facilityFormName,
    validate: (values) => ({ ...contactEmailsUnique(values) }),
  })
)(Facility);
