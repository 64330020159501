import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import { validators } from "@jauntin/utilities";
import { maxFacilityCodeLength } from "@basicare/common/src/Constants/codes";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import {
  facilityCodeValidation,
  facilityOtherAdditionalInsuredZipCode,
  priceFormat,
} from "../../../Helpers/validators";
import {
  additionalInsuredPropType,
  facilityPropType,
} from "../../../Helpers/FacilityModel";
import {
  normalizeFacilityCode,
  normalizeZip,
  normalizeAlphanumeric,
} from "../../../normalizer";
import { facilityFormName, statePropType } from "../../../constants";
import CountryField from "./FormElements/CountryField";
import FacilityCodeField from "./FormElements/FacilityCodeField";
import Debounce from "../../../Helpers/Debounce";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import { getEditFacilityFormImgSrc } from "Redux/Selectors/Facility";
import FacilityLogoUpload from "./FacilityLogoUpload";
import ProductCodeField from "./FormElements/ProductCodeField";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import APSPlanIdField from "./FormElements/APSPlanIdField";
import APSGroupIdField from "./FormElements/APSGroupIdField";
import ElixirGroupIdField from "./FormElements/ElixirGroupIdField";
import { CheckboxInput } from "@jauntin/react-ui";
import {
  recuroGroupId,
  normalizePrice,
} from "@basicare/common/src/Helpers/bcxNormalizers";

const { required, alphaNumeric, maxLength } = validators;
const maxLength20 = maxLength(20);

const debouncer = new Debounce({ period: 500 });

const Information = ({
  editing,
  facility,
  validFacilityCode,
  setValidFacilityCode,
  hasCheckedFacilityCode,
  setHasCheckedFacilityCode,
  checkAndSetValidFacilityCode,
  otherInsured,
  states,
  facilityCodeCounter,
}) => {
  const facilityLogoSrc = useSelector(getEditFacilityFormImgSrc);
  const dispatch = useDispatch();

  const selectFacilityLogo = (file) => {
    dispatch(change(facilityFormName, "facilityImgFile", file));
  };

  const clearFacilityLogo = () => {
    dispatch(change(facilityFormName, "facilityImgFile", null));
    dispatch(change(facilityFormName, "facilityLogoUrl", null));
  };

  const stateOptions = useMemo(() => {
    return states.map((usState) => ({
      label: usState.name,
      value: usState.code,
    }));
  }, [states]);

  return (
    <div className="card w-100">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Organization Information</strong>
        </div>
      </div>
      {editing ? (
        <>
          <div className="card-body">
            <Row>
              <Col md={10}>
                <Field
                  component={FacilityCodeField}
                  validate={[required, facilityCodeValidation]}
                  name="facilityCode"
                  entityType="Organization"
                  type="text"
                  normalize={normalizeFacilityCode}
                  maxLength={maxFacilityCodeLength}
                  onChange={(e) => {
                    const normalizedValue = normalizeFacilityCode(
                      e.target.value
                    );
                    if (normalizedValue === facility.code) {
                      setValidFacilityCode(true);
                    }
                    if (
                      normalizedValue &&
                      normalizedValue !== facility.code &&
                      normalizedValue.length <= maxFacilityCodeLength
                    ) {
                      setHasCheckedFacilityCode(false);
                      debouncer.do(
                        checkAndSetValidFacilityCode,
                        normalizedValue,
                        setHasCheckedFacilityCode,
                        setValidFacilityCode
                      );
                    }
                  }}
                  hasCheckedFacilityCode={hasCheckedFacilityCode}
                  validFacilityCode={validFacilityCode}
                />
                <div className="text-right policy--charCount">
                  {facilityCodeCounter}/{maxFacilityCodeLength} characters
                </div>
                {hasCheckedFacilityCode && !validFacilityCode && (
                  <div className="form-row">
                    <div className="col-sm form-group form-error">
                      The Organization Code has already been taken.
                    </div>
                  </div>
                )}
                <Field
                  component={TextField}
                  validate={[required]}
                  name="facilityName"
                  label="Organization Name"
                  ariaLabel="Organization Name"
                  type="text"
                  inputClassName="form-control-lg col-lg-6 mb-4"
                  errorClassName="d-inline ml-2"
                />
                <Field
                  component={TextField}
                  validate={[required, priceFormat]}
                  normalize={normalizePrice}
                  label="Monthly Price"
                  ariaLabel="Monthly Price"
                  name="monthlyPrice"
                  type="text"
                  inputClassName="form-control-lg col-lg-6"
                  errorClassName="d-inline ml-2"
                />
                <div className="form-row">
                  <Col mb={6}>
                    <Field
                      component={ProductCodeField}
                      validate={[required]}
                      name="productCode"
                      status={facility.status}
                    />
                  </Col>
                  <Col mb={6}>
                    <Field
                      component={TextField}
                      validate={[alphaNumeric]}
                      normalize={normalizeAlphanumeric(255)}
                      label="Recuro Agent ID"
                      ariaLabel="Recuro Agent ID"
                      name="recuroAgentId"
                      type="text"
                      inputClassName="form-control-lg"
                      errorClassName="d-inline ml-2"
                    />
                  </Col>
                </div>
                <div className="form-row">
                  <Col mb={6}>
                    <Field
                      component={APSPlanIdField}
                      validate={[required]}
                      name="apsPlanId"
                    />
                  </Col>
                  <Col mb={6}>
                    <Field
                      component={APSGroupIdField}
                      validate={[required]}
                      name="apsGroupId"
                    />
                  </Col>
                </div>
                <div className="form-row">
                  <Col mb={6}>
                    <Field
                      component={ElixirGroupIdField}
                      validate={[required]}
                      name="elixirGroupId"
                    />
                  </Col>
                  <Col mb={6}>
                    <Field
                      component={TextField}
                      inputClassName="form-control-lg"
                      label="Recuro Group ID"
                      name="recuroGroupId"
                      validate={[required, alphaNumeric, maxLength20]}
                      normalize={recuroGroupId}
                      errorClassName="d-inline ml-2"
                    />
                  </Col>
                </div>
                <Field
                  component={CheckboxInput}
                  name="requiresRecuroSubscriberNumber"
                  type="checkbox"
                  className="d-flex align-items-center"
                  ariaLabel="Requires Recuro Subscriber Number"
                  labelClassName="pl-2"
                  label="Requires Recuro Subscriber Number"
                />
                <Field
                  component={CheckboxInput}
                  name="doNotSendEmailsToMember"
                  type="checkbox"
                  className="d-flex align-items-center"
                  ariaLabel="Do not send emails to the member"
                  labelClassName="pl-2"
                  label="Do not send emails to the member"
                />
                <Field
                  component={TextField}
                  name="otherInsured.address1"
                  type="text"
                  label="Street address"
                  ariaLabel="Street address"
                  inputClassName="form-control-lg mb-2"
                  className="mb-0"
                />
                <Field
                  component={TextField}
                  name="otherInsured.address2"
                  type="text"
                  ariaLabel="Address Line 2"
                  inputClassName="form-control-lg mb-2"
                />
                <div className="form-row">
                  <div className="col-sm">
                    <Field
                      component={TextField}
                      name="otherInsured.city"
                      type="text"
                      label="City"
                      ariaLabel="City"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      name="otherInsured.state"
                      component={SelectList}
                      customStyles={getLgSelectStyle}
                      label="State"
                      placeholder="State"
                      options={stateOptions}
                      isClearable={true}
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      component={TextField}
                      validate={[facilityOtherAdditionalInsuredZipCode]}
                      normalize={normalizeZip}
                      name="otherInsured.zip"
                      type="text"
                      label="Zipcode"
                      ariaLabel="Zip"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div hidden>
                    <Field
                      name="otherInsured.country"
                      component={CountryField}
                      type="select"
                      input={{ disabled: true }}
                      hidden="true"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <FacilityLogoUpload
              imgSrc={facilityLogoSrc}
              onSelect={selectFacilityLogo}
              onClear={clearFacilityLogo}
            />
          </div>
        </>
      ) : (
        <>
          <div className="card-body">
            <p className="label">Organization Code</p>
            <p className="mb-4">{facility.code}</p>
            <p className="label">Organization Name</p>
            <p className="mb-4">{facility.name}</p>
            <p className="label">Monthly Price</p>
            <p className="mb-4">{facility.monthlyPrice}</p>

            <p className="label">Product Code</p>
            <p className="mb-4">{facility.productCode}</p>

            {facility.recuroAgentId && (
              <>
                <p className="label">Recuro Agent ID</p>
                <p className="mb-4">{facility.recuroAgentId}</p>
              </>
            )}

            <p className="label">APS Plan ID</p>
            <p className="mb-4">{facility.apsPlanId}</p>

            <p className="label">APS Group ID</p>
            <p className="mb-4">{facility.apsGroupId}</p>

            <p className="label">Elixir Group ID</p>
            <p className="mb-4">{facility.elixirGroupId}</p>

            <p className="label">Recuro Group ID</p>
            <p className="mb-4">{facility.recuroGroupId}</p>

            {Boolean(facility.requiresRecuroSubscriberNumber) && (
              <>
                <p className="mb-4">Requires Recuro Subscriber Number</p>
              </>
            )}

            {Boolean(facility.doNotSendEmailsToMember) && (
              <>
                <p className="mb-4">Emails are not sent to the member</p>
              </>
            )}

            {!!otherInsured.id && (
              <p>
                <span className="d-flex">
                  {`${otherInsured.address1}${
                    otherInsured.address1 && otherInsured.address2
                      ? `, ${otherInsured.address2}`
                      : ""
                  }`}
                </span>
                <span className="d-flex">
                  {`${otherInsured.city}${
                    otherInsured.city && otherInsured.state ? `,` : ""
                  } ${otherInsured.state} ${otherInsured.zip}`}
                </span>
              </p>
            )}

            {facility.logoUrl && (
              <div className="mt-3">
                <p className="label">Organization Logo</p>
                <div className="d-flex align-items-center justify-content-center py-4 my-3 border">
                  <img src={facility.logoUrl} alt="Organization Logo" />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

Information.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
  validFacilityCode: PropTypes.bool.isRequired,
  setValidFacilityCode: PropTypes.func.isRequired,
  hasCheckedFacilityCode: PropTypes.bool.isRequired,
  setHasCheckedFacilityCode: PropTypes.func.isRequired,
  checkAndSetValidFacilityCode: PropTypes.func.isRequired,
  otherInsured: additionalInsuredPropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  facilityCodeCounter: PropTypes.number.isRequired,
};

export default Information;
