import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { facilityPropType } from "../../../Helpers/FacilityModel";
import ContactFields from "./FormElements/ContactFields";
import { ContactCards } from "@jauntin/react-ui";

const Contact = ({ editing, facility }) => {
  return (
    <>
      {editing ? (
        <FieldArray name="contacts" component={ContactFields} wide />
      ) : (
        <ContactCards
          contacts={facility.facilityContacts}
          cardTitle="Organization Contact"
          className="py-3"
          cardClassName="w-100 mt-4"
          headerClassName="bg-transparent font-weight-bold"
          copyOnEmailsLabel="Copied on email containing member information"
        />
      )}
    </>
  );
};

Contact.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
};

export default Contact;
