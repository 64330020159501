import { combineLatest, from, merge } from "rxjs";
import { map, share } from "rxjs/operators";
import {
  Reducers,
  RxRequest,
  RequestState,
  RequestActions,
} from "@jauntin/reactables";
import { Reactable, ofTypes } from "@reactables/core";

import MembershipService from "../../../Services/MembershipService";
import { RxLoadMember } from "./RxLoadMember";
import {
  RxSaveMember,
  SaveMemberActions,
  SaveMemberState,
} from "./RxSaveMember";
import {
  RxCancelMember,
  CancelMemberActions,
  CancelMemberState,
} from "./RxCancelMember";
import {
  RxSendMemberEmails,
  SendMemberEmailsActions,
  SendMemberEmailsState,
} from "./RxSendMemberEmails";
import { MemberDetails } from "./Models/memberDetails.model";
import { HookedReactable } from "@reactables/react";
import FacilityService from "Services/FacilityService";

export interface MemberState {
  member: Reducers.LoadableState<MemberDetails>;
  saveMember: SaveMemberState;
  cancelMember: CancelMemberState;
  sendMemberEmails: SendMemberEmailsState;
  paymentMethodUpdateLinkRequest: RequestState<{ success: boolean }>;
  addNoteRequest: RequestState<unknown>;
}

export type MemberActions = {
  loadMember: () => void;
  paymentMethodUpdateLink: RequestActions<number>;
  addNote: RequestActions<{ id: number; message: string }>;
} & SaveMemberActions &
  SendMemberEmailsActions &
  CancelMemberActions;

export type RxMemberProp = HookedReactable<MemberState, MemberActions>;

export const RxMember = ({
  membershipService,
  facilityService,
  id,
  onSaveMemberSuccess,
}: {
  membershipService: MembershipService;
  facilityService: FacilityService;
  id: number;
  onSaveMemberSuccess: () => void;
}): Reactable<MemberState, MemberActions> => {
  const [sendMemberEmails$, sendMemberEmailsActions, sendMemberEmailsActions$] =
    RxSendMemberEmails({
      membershipService,
    });

  const sendContactsAndEmailsSuccess$ = sendMemberEmailsActions$.pipe(
    ofTypes(["sendContactsAndEmailsSuccess"])
  );

  const [saveMember$, { saveMember, saveMemberReset }, saveMemberActions$] =
    RxSaveMember({
      membershipService,
      id,
      onSaveMemberSuccess,
    });

  const saveMemberSuccess$ = saveMemberActions$.pipe(
    ofTypes(["saveMemberSuccess"])
  );

  const [addNoteRequest$, addNoteActions, addNoteActions$] = RxRequest<
    { id: number; message: string },
    unknown
  >({
    name: "rxAddNoteRequest",
    effect: (action$) =>
      action$.pipe(
        map(({ payload }) => from(membershipService.addNote(payload)))
      ),
  });

  const addNoteResponse$ = addNoteActions$.pipe(
    ofTypes(["sendSuccess", "sendFailure"]),
    map(({ type }) => ({ type: `${type}AddNote` }))
  );
  const [
    cancelMember$,
    { cancelMember, cancelMemberReset },
    cancelMemberActions$,
  ] = RxCancelMember({
    membershipService,
    id,
  });

  const cancelMemberSuccess$ = cancelMemberActions$.pipe(
    ofTypes(["cancelMemberSuccess"])
  );

  const [member$, { loadMember }] = RxLoadMember({
    membershipService,
    id,
    sources: [
      saveMemberSuccess$,
      sendContactsAndEmailsSuccess$,
      addNoteResponse$,
      cancelMemberSuccess$,
    ],
  });

  const [paymentMethodUpdateLinkRequest$, paymentMethodUpdateLinkActions] =
    RxRequest<number, { success: boolean }>({
      name: "rxSendUpdateLinkRequest",
      effect: (action$) =>
        action$.pipe(
          map(({ payload }) => from(membershipService.sendUpdateLink(payload)))
        ),
    });

  const state$ = combineLatest({
    member: member$,
    saveMember: saveMember$,
    cancelMember: cancelMember$,
    sendMemberEmails: sendMemberEmails$,
    paymentMethodUpdateLinkRequest: paymentMethodUpdateLinkRequest$,
    addNoteRequest: addNoteRequest$,
  }).pipe(share());

  const actions: MemberActions = {
    loadMember,
    saveMember,
    saveMemberReset,
    cancelMember,
    cancelMemberReset,
    ...sendMemberEmailsActions,
    paymentMethodUpdateLink: paymentMethodUpdateLinkActions,
    addNote: addNoteActions,
  };

  const actions$ = merge(
    sendContactsAndEmailsSuccess$,
    addNoteResponse$,
    cancelMemberSuccess$
  );

  return [state$, actions, actions$];
};
