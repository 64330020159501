import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { stringHelpers } from "../../../Helpers/FormattingHelpers";
import copyToClipboard from "Helpers/CopyToClipboard";
import { producerPropType } from "../../../Helpers/ProducerModel";
import StatusField from "./FormElements/StatusField";
import ReferralLinkToast from "../../Shared/Components/ReferralLinkToast";

const ProducerStatus = ({ editing, producer }) => {
  const { referralLink } = producer;
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <div className="card border-0">
        <div className="form-row align-items-center mb-4">
          <div className="col-auto">
            {editing ? (
              <Field
                component={StatusField}
                name="producerStatus"
                type="select"
              />
            ) : (
              <>
                <span className="label mr-4">Status:</span>
                <span>{stringHelpers.firstToUpper(producer.status)}</span>
              </>
            )}
          </div>

          <div className="col d-flex justify-content-end">
            <div className="mb-0 text-right my-auto mr-2">
              <strong>Referral link:</strong>
              <em>{` ${referralLink}`}</em>
            </div>
            <div className="my-auto">
              <button
                className="btn btn-primary px-4 text-nowrap"
                type="button"
                onClick={() => {
                  copyToClipboard(referralLink);
                  setShowToast(true);
                }}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
      <ReferralLinkToast
        show={showToast}
        setShowToast={setShowToast}
        text={`Broker referral link`}
      />
    </>
  );
};

ProducerStatus.propTypes = {
  editing: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
};

export default ProducerStatus;
