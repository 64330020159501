import { connect } from "react-redux";
import { NotesContainer } from "@jauntin/react-ui";
import { getFacility } from "../../../Redux/Actions/actions";
import API from "../../../Services/API";
import FacilityService from "../../../Services/FacilityService";

const mapStateToProps = (state) => ({
  entityId: state.facilities.currentFacility.id,
  notes: state.facilities.currentFacility.notes.map(
    ({ id, message, type, userName, createdAt }) => ({
      id,
      message,
      type,
      userName,
      created: createdAt,
    })
  ),
});

const mapDispatchToProps = (dispatch) => ({
  publish: (id, message) =>
    dispatch(() => {
      return new FacilityService(new API())
        .postAddNote({
          id,
          message,
        })
        .then(() => dispatch(getFacility(id)));
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
