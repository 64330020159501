import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Reactable, Action } from "@reactables/core";
import { ControlModels, RxFormActions } from "@reactables/forms";
import { PromoCodeForm } from "@basicare/common/src/Models/promoCode.model";
import { FormBuilders, asyncValidators } from "@jauntin/reactables";
import PromoCodeService from "Services/PromoCodeService";
import {
  billingCyclesCount,
  promoCode as promoCodeConfig,
} from "./Configs/promoCode.config";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";
import {
  discountAmountDollars,
  discountAmountPercentage,
} from "./Configs/promoCode.config";
import { PlanTypes } from "@basicare/common/src/Constants/planTypes";
import { BillingCycleTypes } from "@basicare/common/src/Constants/billingCycleTypes";

export type PromoCodeFormState = ControlModels.Form<PromoCodeForm>;

export type PromoCodeFormActions = {
  selectPromoCodeType: (type: PromoCodeTypes) => void;
  selectDiscountType: (type: DiscountTypes) => void;
  selectBillingCycleType: (type: BillingCycleTypes) => void;
  clearEndDate: () => void;
} & RxFormActions;

const selectDiscountType = (
  { updateValues, removeControl, addControl },
  state,
  { payload }: Action<DiscountTypes>
) => {
  state = updateValues(state, {
    controlRef: ["discountType"],
    value: payload,
  });

  state = removeControl(state, ["discountAmount"]);

  state = addControl(state, {
    controlRef: ["discountAmount"],
    config:
      payload === DiscountTypes.Fixed
        ? discountAmountDollars()
        : discountAmountPercentage(),
  });

  return state;
};

const selectBillingCycleType = (
  { addControl, removeControl, updateValues },
  state,
  { payload }: Action<BillingCycleTypes>
) => {
  if (payload === BillingCycleTypes.Limited) {
    state = addControl(state, {
      controlRef: ["billingCycles", "count"],
      config: billingCyclesCount(),
    });
  } else {
    state = removeControl(state, ["billingCycles", "count"]);
  }

  state = updateValues(state, {
    controlRef: ["billingCycles", "type"],
    value: payload,
  });

  return state;
};

export const RxPromoCodeForm = ({
  promoCodeService,
  promoCodeForm,
  sources = [],
  redeems,
}: {
  promoCodeService: PromoCodeService;
  promoCodeForm?: PromoCodeForm;
  sources?: Observable<Action<unknown>>[];
  redeems?: number;
}) =>
  FormBuilders.build(promoCodeConfig(promoCodeForm), {
    reducers: {
      selectPromoCodeType: (
        formReducers,
        state,
        { payload }: Action<PromoCodeTypes>
      ) => {
        const { updateValues, resetControl } = formReducers;
        state = updateValues(state, {
          controlRef: ["type"],
          value: payload,
        });

        state = selectDiscountType(formReducers, state, {
          payload: DiscountTypes.Percent,
        });

        if (
          payload === PromoCodeTypes.Trial ||
          payload === PromoCodeTypes.TrialPurchase
        ) {
          state = updateValues(state, {
            controlRef: ["subscriptionInterval"],
            value: PlanTypes.Monthly,
          });
        } else {
          state = resetControl(state, ["subscriptionInterval"]);
        }

        const {
          type,
          billingCycles: { type: billingCyclesType },
        } = state.form.root.value as PromoCodeForm;

        if (
          type === PromoCodeTypes.TrialPurchase &&
          billingCyclesType === BillingCycleTypes.Unlimited
        ) {
          state = selectBillingCycleType(formReducers, state, {
            payload: BillingCycleTypes.Limited,
          });
        }

        return state;
      },
      selectDiscountType,
      clearEndDate: ({ updateValues }, state) =>
        updateValues(state, {
          controlRef: ["dateRange"],
          value: {
            ...(state.form.root.value as PromoCodeForm).dateRange,
            endDate: null,
          },
        }),
      promoCodeGenerated: (
        { updateValues },
        state,
        { payload: promoCode }: Action<string>
      ) => {
        state = updateValues(state, {
          controlRef: ["code"],
          value: promoCode,
        });

        return state;
      },
      selectBillingCycleType,
    },
    sources,
    providers: {
      ...formProviders,
      validators: {
        ...formProviders.validators,
        usageGreaterThanRedeems: (value: string) => ({
          usageGreaterThanRedeems:
            promoCodeForm == null
              ? false
              : value.trim() && Number(value) < Number(redeems),
        }),
      },
      asyncValidators: asyncValidators([
        {
          name: "uniquePromoCode",
          resource: (value) =>
            from(promoCodeService.getIsValidPromoCode(value)).pipe(
              map(({ data: { valid } }) => valid)
            ),
        },
      ]),
    },
  }) as Reactable<PromoCodeFormState, PromoCodeFormActions>;
