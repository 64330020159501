import { useReactable } from "@reactables/react";
import { useHistory } from "react-router-dom";
import { RxToggle } from "@jauntin/reactables";
import { useSelector } from "react-redux";
import { Button } from "@jauntin/react-ui";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import { getSaveMemberPayload } from "Features/Members/Rx/Selectors/member.selector";
import MemberForm from "./MemberForm";
import { RxMemberProp } from "Features/Members/Rx/RxMember";
import { RxMemberForm } from "Features/Members/Rx/RxMemberForm";
import ModalConfirmation from "@basicare/common/src/Components/ModalConfirmation";
import { MEMBER_PAGE, getUrl } from "Helpers/URLParser";
import MembershipService from "../../../Services/MembershipService";
import API from "../../../Services/API";
import { RxOrganizationTypeahead } from "../Rx/RxOrganizationTypeahead";
import FacilityService from "Services/FacilityService";
import { Reducers } from "@jauntin/reactables";
import { memberFromMemberDetails } from "../Rx/Models/member.model";

const EditMember = ({ rxMember }: { rxMember: RxMemberProp }) => {
  const history = useHistory();
  const usStates = useSelector(
    (state: { app: { states } }) => state.app.states
  );

  const [memberState, actions] = rxMember;

  const {
    member: { data: member },
  } = memberState;

  const [formState, formActions] = useReactable(
    RxMemberForm,
    new MembershipService(new API()),
    member
  );

  const [organizationTypeahead, { searchOrganizations }] = useReactable(
    RxOrganizationTypeahead,
    {
      facilityService: new FacilityService(new API()),
      initialSearch: {
        search: "",
        productCode: member.organization.productCode,
      },
      initialState: {
        ...Reducers.loadableInitialState,
        data: [memberFromMemberDetails(member).membership.organization],
      },
    }
  );
  const [showConfirmModal, confirmModalToggle] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  if (!usStates.length || !formState) return;

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Edit Member</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={discardModalToggle.toggleOn}
            />
            <Button
              className="btn btn-primary px-4 mx-2 text-nowrap"
              onClick={confirmModalToggle.toggleOn}
              text="Save Changes"
              disabled={
                !formState.root.valid ||
                !formState.root.dirty ||
                formState.root.pending
              }
            />
          </div>
        </div>
      </div>
      <MemberForm
        rxMemberForm={[formState, formActions]}
        subscriberNumber={member.subscriberNumber}
        organizationOptions={organizationTypeahead}
        usStates={usStates}
        status={member.status}
        onOrgChange={(organization) => {
          formActions.updateValues({
            controlRef: ["membership", "organization"],
            value: organization,
          });

          if (!organization) {
            searchOrganizations({
              search: "",
              productCode: member.organization.productCode,
            });
          }
        }}
        onInputChange={(search) =>
          searchOrganizations({
            search,
            productCode: member.organization.productCode,
          })
        }
      />
      {showConfirmModal && (
        <ModalConfirmation
          message={`Update ${member.firstName} ${member.lastName} member?`}
          cancelBtnText="Back to Edit"
          checkboxLabelText="Yes, I confirm that I would like to update this member."
          show={showConfirmModal}
          handleClose={confirmModalToggle.toggleOff}
          action={() =>
            actions.saveMember(getSaveMemberPayload(formState.root.value))
          }
          isUpdating={memberState.saveMember.loading}
        />
      )}

      <ModalDiscardAddNew
        show={showDiscardModal}
        handleClose={discardModalToggle.toggleOff}
        confirmAction={() => {
          history.push(getUrl(MEMBER_PAGE, member.id));
        }}
      />

      <ModalUpdateError
        show={Boolean(memberState.saveMember.error)}
        text="There was an error updating a member. Please try again"
        handleCloseError={actions.saveMemberReset}
      />
    </>
  );
};

export default EditMember;
