import { useCallback } from "react";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ofTypes, Action } from "@reactables/core";

/**
 * @description filters actions$ observable for addNote response and converts to promise
 * so publish function can be used with NotesContainer from @jauntin/react-ui
 */
export const useNotePublisher = ({
  actions$,
  publish,
}: {
  actions$: Observable<Action<unknown>>;
  publish: (params: { id: number; message: string }) => void;
}) =>
  useCallback(
    (id, message) => {
      const response$ = actions$.pipe(
        ofTypes(["sendSuccessAddNote", "sendFailureAddNote"]),
        take(1)
      );

      return new Promise((resolve, reject) => {
        response$.subscribe(({ type }) => {
          if (type === "sendSuccessAddNote") {
            resolve(type);
          } else {
            reject(type);
          }
        });

        publish({ id, message });
      });
    },
    [publish, actions$]
  );
