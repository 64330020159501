/**
 *
 * Custom reducers for Redux Form
 *
 */
import {
  loginFormName,
  producerFormName,
  facilityFormName,
  addFacilityForm,
  newUserForm,
} from "../../constants";
import {
  SET_FORM_VALUES,
  SET_GENERATED_FACILITY_CODE,
  SET_DEFAULT_ADD_FACILITY_COMMISSION_RATE,
  USER_SAVE_SUCCESS,
} from "../Actions/actions";

const setValues = (state, action, formName) => {
  const updated = { ...state };
  if (action.type === SET_FORM_VALUES && action.payload.formName === formName) {
    updated.values = updated.values || {};
    const { values } = action.payload;
    Object.keys(values || {}).forEach((key) => {
      updated.values[key] = values[key];
    });
    return updated;
  }
  return state;
};

const setDefaultAddFacilityCommissionRate = (state, data) => {
  const updated = { ...state };
  updated.values.facilityCommissionRate = data;
  return updated;
};

const setGeneratedFacilityCode = (state, data) => {
  const updated = { ...state };
  updated.values.facilityCode = data;
  return updated;
};

export default {
  [loginFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, loginFormName) };
  },
  [producerFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, producerFormName) };
  },
  [facilityFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, facilityFormName) };
  },
  [addFacilityForm]: (state, action) => {
    if (action.type === SET_GENERATED_FACILITY_CODE) {
      return { ...setGeneratedFacilityCode(state, action.payload) };
    }
    if (action.type === SET_DEFAULT_ADD_FACILITY_COMMISSION_RATE) {
      return { ...setDefaultAddFacilityCommissionRate(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, addFacilityForm) };
  },
  [newUserForm]: (state, action) => {
    if (action.type === USER_SAVE_SUCCESS) {
      return undefined;
    }
    return state;
  },
};
