import { useReactable } from "@reactables/react";
import { Spinner } from "react-bootstrap";
import { Switch, Route, useParams, useHistory } from "react-router-dom";
import {
  getUrl,
  EDIT_PROMO_CODE_PAGE,
  PROMO_CODE_PAGE,
} from "Helpers/URLParser";
import { RxPromoCode } from "Features/PromoCodes/Rx/RxPromoCode";
import PromoCodeService from "Services/PromoCodeService";
import API from "Services/API";
import PromoCodeView from "./PromoCodeView";
import EditPromoCode from "./EditPromoCode";

const PromoCodeContainer = () => {
  const { id } = useParams() as { id: string };
  const history = useHistory();
  const rxPromoCode = useReactable(RxPromoCode, {
    promoCodeService: new PromoCodeService(new API()),
    id: parseInt(id),
    onEditSuccess: () => {
      history.push(getUrl(PROMO_CODE_PAGE, id));
    },
  });

  const [state] = rxPromoCode;

  if (!state) return;

  if (state.promoCode.loading && !state.promoCode.data)
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        <Spinner animation="border" role="status" />
        <span className="ml-3">Loading Promo Code...</span>
      </div>
    );

  return (
    <div className="scroll-part">
      <div className="subsection__wrapper">
        <div className="subsection__container">
          <div>
            <Switch>
              <Route path={getUrl(PROMO_CODE_PAGE)}>
                <PromoCodeView rxPromoCode={rxPromoCode} />
              </Route>
              <Route path={getUrl(EDIT_PROMO_CODE_PAGE)}>
                <EditPromoCode rxPromoCode={rxPromoCode} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoCodeContainer;
