import { of, Observable, from } from "rxjs";
import { Action, Reactable } from "@reactables/core";
import { Reducers, RxRequest, RequestState } from "@jauntin/reactables";
import { debounceTime, map } from "rxjs/operators";
import FacilityService from "Services/FacilityService";
import { Organization } from "@basicare/common/src/Models/organization.model";

interface OrganizationSearchPayload {
  search: string;
  productCode?: string;
}

export type OrganizationTypeaheadState = RequestState<Organization[]>;

export interface OrganizationTypeaheadActions {
  searchOrganizations: (payload: OrganizationSearchPayload) => void;
}

export const RxOrganizationTypeahead = ({
  facilityService,
  initialSearch = { search: "" },
  initialState = { ...Reducers.loadableInitialState, data: [] },
}: {
  facilityService: FacilityService;
  initialSearch?: OrganizationSearchPayload;
  initialState?: Reducers.LoadableState<Organization[]>;
}): Reactable<OrganizationTypeaheadState, OrganizationTypeaheadActions> => {
  const [state$, { send: searchOrganizations }] = RxRequest<
    OrganizationSearchPayload,
    Organization[]
  >({
    name: "rxOrganizationTypeahead",
    initialState,
    sources: [of({ type: "send", payload: initialSearch })],
    effect: (
      action$: Observable<Action<{ search: string; productCode?: string }>>
    ) =>
      action$.pipe(
        debounceTime(500),
        map(({ payload: { search, productCode } }) => {
          return from(
            facilityService.getSearchFacilities(search, productCode)
          ).pipe(map(({ data: { data } }) => data)) as Observable<
            Organization[]
          >;
        })
      ),
  });

  return [state$, { searchOrganizations }];
};
