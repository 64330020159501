import React from "react";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@basicare/common/src/Constants/ReduxFormPropTypes";
import { DEFAULT_APS_GROUP_ID } from "../../../../constants";

const values = [DEFAULT_APS_GROUP_ID];

const APSGroupIdField = ({ input, meta }) => (
  <div className="form-group">
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      APS Group ID
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <select
      {...input}
      name={input.name}
      id={input.name}
      className="custom-select custom-select-lg"
      required
    >
      {values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  </div>
);

APSGroupIdField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
};

export default APSGroupIdField;
