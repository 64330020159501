import React from "react";
import { useReactable } from "@reactables/react";
import { Spinner } from "react-bootstrap";
import MembersMenu from "./MembersMenu";
import {
  Switch,
  Route,
  useParams,
  useHistory,
  Redirect,
} from "react-router-dom";
import {
  EDIT_MEMBER_PAGE,
  getUrl,
  MEMBER_PAGE,
  CONTACT_PAGE,
  MEMBERS_NOTES_PAGE,
} from "Helpers/URLParser";
import { RxMember } from "../Rx/RxMember";
import MembershipService from "Services/MembershipService";
import API from "Services/API";
import MemberView from "./MemberView";
import EditMember from "./EditMember";
import MemberContacts from "./MemberContacts";
import MemberNotes from "./MemberNotes";
import GuardedRoute from "@basicare/common/src/Components/GuardedRoute";
import FacilityService from "Services/FacilityService";

const Member = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const rxMember = useReactable(RxMember, {
    membershipService: new MembershipService(new API()),
    facilityService: new FacilityService(new API()),
    id: parseInt(id),
    onSaveMemberSuccess: () => {
      history.push(getUrl(MEMBER_PAGE, id));
    },
  });

  const [state] = rxMember;

  if (!state) return;

  if (state.member.loading && !state.member.data)
    return (
      <div className="d-flex justify-content-center align-items-center h-100 w-100">
        <Spinner animation="border" role="status" />
        <span className="ml-3">Loading Member...</span>
      </div>
    );

  const GuardRedirectComponent = () => (
    <Redirect to={getUrl(MEMBER_PAGE, id)} />
  );

  return (
    <>
      <div className="subsection__wrapper">
        <MembersMenu rxMember={rxMember} />
        <div className="subsection__container">
          <div>
            <Switch>
              <Route path={getUrl(MEMBER_PAGE)}>
                <MemberView rxMember={rxMember} />
              </Route>
              <GuardedRoute
                exact
                path={getUrl(CONTACT_PAGE)}
                component={() => <MemberContacts rxMember={rxMember} />}
                guards={[
                  () => !state.member.data.organization.doNotSendEmailsToMember,
                ]}
                redirectComponent={GuardRedirectComponent}
              />
              <Route path={getUrl(EDIT_MEMBER_PAGE)}>
                <EditMember rxMember={rxMember} />
              </Route>
              <Route path={getUrl(MEMBERS_NOTES_PAGE)}>
                <MemberNotes rxMember={rxMember} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Member;
