import { from, of, forkJoin } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ControlModels } from "@reactables/forms";
import { Reactable, Action } from "@reactables/core";
import { Reducers, RxBuilder } from "@jauntin/reactables";
import { MemberContactsForm } from "./Models/memberContactsForm.model";
import MembershipService from "Services/MembershipService";
import { CustomContact, MemberDetails } from "./Models/memberDetails.model";
import { MemberEmailsPayload } from "./Models/memberEmailsPayload.model";

export type SendMemberEmailsState = Reducers.LoadableState<null>;
export type SendMemberEmailsActions = {
  sendContactsAndEmails: ({
    form,
    memberDetails,
  }: {
    form: ControlModels.Form<MemberContactsForm>;
    memberDetails: MemberDetails;
  }) => void;
  resetSendContactsAndEmails: () => void;
};

export const RxSendMemberEmails = ({
  membershipService,
}: {
  membershipService: MembershipService;
}): Reactable<SendMemberEmailsState, SendMemberEmailsActions> =>
  RxBuilder({
    name: "rxSendMemberEmails",
    initialState: Reducers.loadableInitialState,
    reducers: {
      sendContactsAndEmails: {
        reducer: Reducers.load,
        effects: [
          ($action) =>
            $action.pipe(
              mergeMap(
                ({
                  payload: { form, memberDetails },
                }: Action<{
                  form: ControlModels.Form<MemberContactsForm>;
                  memberDetails: MemberDetails;
                }>) => {
                  const {
                    id,
                    organization: { contacts, facilityProducerContacts },
                  } = memberDetails;
                  const {
                    customContacts,
                    emailPrimaryMember,
                    emailOrganizationContacts,
                    emailOrganizationProducerContacts,
                  } = form.root.value;

                  const customContactsPayload = customContacts.map(
                    ({ contactInfo }) =>
                      Object.entries(contactInfo).reduce(
                        (acc, [key, value]) => {
                          if (key === "id" && value === null) return acc;

                          return {
                            ...acc,
                            [key]: value,
                          };
                        },
                        {}
                      )
                  ) as CustomContact[];

                  const emailPayload: MemberEmailsPayload = {
                    id,
                    mainRecipients: emailPrimaryMember
                      ? [memberDetails.email]
                      : [],
                    bccRecipients: [
                      ...contacts
                        .filter(
                          ({ copyOnEmails }, index) =>
                            emailOrganizationContacts[index] && copyOnEmails
                        )
                        .map(({ email }) => email),
                      ...facilityProducerContacts
                        .filter(
                          (_, index) => emailOrganizationProducerContacts[index]
                        )
                        .map(({ email }) => email),
                      ...customContacts
                        .filter(({ sendEmail }) => sendEmail)
                        .map(({ contactInfo: { email } }) => email),
                    ],
                  };

                  return forkJoin([
                    from(
                      membershipService.updateCustomContacts(
                        id,
                        customContactsPayload
                      )
                    ),
                    from(
                      membershipService.sendWelcomeEmails(
                        emailPayload,
                        memberDetails.id
                      )
                    ).pipe(),
                  ]).pipe(
                    map(([{ data: updatedContacts }]) => ({
                      type: "sendContactsAndEmailsSuccess",
                      payload: {
                        updatedContacts,
                      },
                    })),
                    catchError((error) =>
                      of({
                        type: "sendContactsAndEmailsFailure",
                        payload: error,
                      })
                    )
                  );
                }
              )
            ),
        ],
      },
      sendContactsAndEmailsSuccess: Reducers.loadSuccess,
      sendContactsAndEmailsFailure: Reducers.loadError,
      resetSendContactsAndEmails: () => Reducers.loadableInitialState,
    },
  });
