import { useReactable } from "@reactables/react";
import { RxToggle } from "@jauntin/reactables";
import { ExtendedMeta } from "@jauntin/utilities";
import { useSelector } from "react-redux";
import { Button } from "@jauntin/react-ui";
import { useHistory } from "react-router-dom";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { MEMBERS_PAGE, getUrl } from "Helpers/URLParser";
import FacilityService from "Services/FacilityService";
import MembershipService from "Services/MembershipService";
import API from "Services/API";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import {
  getAddMemberPayload,
  getShowAddMemberActionBtn,
} from "Features/Members/Rx/Selectors/member.selector";
import { RxAddMember } from "Features/Members/Rx/RxAddMember";
import MemberForm from "./MemberForm";

const AddMember = () => {
  const history = useHistory();

  const usStates = useSelector(
    (state: { app: { states } }) => state.app.states
  );

  const goToMembersPage = () => history.push(getUrl(MEMBERS_PAGE));

  const [state, actions] = useReactable(RxAddMember, {
    facilityService: new FacilityService(new API()),
    membershipService: new MembershipService(new API()),
    onAddMemberSuccess: goToMembersPage,
  });

  const [submitTouched, { toggleOn: touchSubmit }] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  if (!state || !usStates.length) return;

  const actionEnabled = getShowAddMemberActionBtn(state, submitTouched);

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || submitTouched) && error);

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add New Member</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={discardModalToggle.toggleOn}
            />
            <TwoStageButton
              className="btn btn-primary px-4 mx-2 text-nowrap border-radius-0"
              spinning={state.addMemberSubmission.loading}
              actionEnabled={actionEnabled}
              action={() =>
                actions.addMemberSubmission.addMember(
                  getAddMemberPayload(state)
                )
              }
              touchSubmit={touchSubmit}
              text="Save Changes"
              disableAction={
                !state.form.root.valid ||
                !state.form.root.dirty ||
                state.form.root.pending
              }
            />
          </div>
        </div>
      </div>
      <MemberForm
        rxMemberForm={[state.form, actions.form]}
        organizationOptions={state.organizationTypeahead}
        usStates={usStates}
        showErrors={showErrors}
        onOrgChange={(organization) => {
          actions.form.updateValues({
            controlRef: ["membership", "organization"],
            value: organization,
          });

          if (!organization) {
            actions.searchOrganizations({ search: "" });
          }
        }}
        onInputChange={(search) => actions.searchOrganizations({ search })}
      />

      <ModalDiscardAddNew
        show={showDiscardModal}
        handleClose={discardModalToggle.toggleOff}
        confirmAction={goToMembersPage}
      />

      <ModalUpdateError
        show={Boolean(state.addMemberSubmission.error)}
        text="There was an error creating a member. Please try again"
        handleCloseError={actions.addMemberSubmission.resetSubmissionState}
      />
    </>
  );
};

export default AddMember;
