import { ControlModels, RxFormActions } from "@reactables/forms";
import { Form, Field, FormArray } from "@reactables/react-forms";
import { Row, Col, Card } from "react-bootstrap";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import AddressFieldGroup from "@basicare/common/src/Components/FormElements/AddressFieldGroupNew";
import { Button, PhoneInput, EmailInput } from "@jauntin/react-ui";
import { ExtendedMeta } from "@jauntin/utilities";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import { Organization } from "@basicare/common/src/Models/organization.model";
import { Reducers } from "@jauntin/reactables";
import { genderOptions } from "@basicare/common/src/Constants/genders";
import { UsState } from "@jauntin/react-ui/dist/Components/StateField/StateField";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { RelationshipType } from "@basicare/common/src/Constants/dependents";
import DependentFields from "@basicare/common/src/Components/FormElements/DependentFields";
import { MAX_DEPENDENTS } from "@basicare/common/src/Constants/dependents";
import { MemberFormActions } from "Features/Members/Rx/RxMemberForm";
import RecuroSubscriberNumberField from "@basicare/common/src/Components/FormElements/RecuroSubscriberNumberField";
import { MemberStatuses } from "@basicare/common/src/Constants/memberStatuses";

const MemberForm = ({
  groupName,
  rxMemberForm,
  organizationOptions,
  usStates,
  showErrors = ({ touched, error }: ExtendedMeta) => Boolean(touched && error),
  onOrgChange,
  onInputChange,
  subscriberNumber,
  status,
  disableOrganization = false,
}: {
  rxMemberForm: [
    ControlModels.Form<unknown>,
    MemberFormActions & RxFormActions
  ];
  organizationOptions: Reducers.LoadableState<Organization[]>;
  usStates: UsState[];
  showErrors?: (meta: ExtendedMeta) => boolean;
  onOrgChange?: (org: Organization) => void;
  onInputChange?: (value: string) => void;
  groupName?: string;
  subscriberNumber?: string;
  status?: string;
  disableOrganization?: boolean;
}) => {
  groupName = groupName ? `${groupName}.` : "";
  const [state, actions] = rxMemberForm;
  const selectedOrganization = state[`${groupName}membership.organization`]
    .value as Organization | null;

  const dependents = state[`${groupName}dependents`].value as Dependent[];

  const disableSpouseRadio = dependents.some(
    (dependent) =>
      dependent.relationshipToAccountHolder === RelationshipType.Spouse
  );

  return (
    <>
      <Form rxForm={rxMemberForm}>
        <div className="content__body">
          <div className="card mb-4">
            <div className="card-header bg-transparent d-flex justify-content-between">
              <div className="my-auto contacts__cardTitle">
                <strong>Membership Information</strong>
              </div>
            </div>
            <div className="card-body">
              <Row>
                <Col md={6}>
                  <Field
                    memberFormGroup={state.membership}
                    recuroSubNumControl={
                      state[
                        `${groupName}membership.recuroSubscriberNumber`
                      ] as ControlModels.FormControl<string>
                    }
                    name={`${groupName}membership.recuroSubscriberNumber`}
                    component={RecuroSubscriberNumberField}
                    inputClassName="form-control-lg"
                    showErrors={showErrors}
                    disabled={
                      !Boolean(selectedOrganization) ||
                      status === MemberStatuses.Active
                    }
                  />
                </Col>
                <Col md={6}>
                  <Field
                    name={`${groupName}membership.effectiveDate`}
                    component={TextField}
                    label="Effective Date"
                    placeholder="mm/dd/yyyy"
                    inputClassName="form-control-lg date-field"
                    errorMessages={{
                      effectiveDate: "Invalid Date",
                      threeMonthsInThePast:
                        "Cannot be more than 3 months in the past.",
                    }}
                    showErrors={showErrors}
                    disabled={status === MemberStatuses.Active}
                  />
                </Col>
                {state[`${groupName}membership.cancellationDate`]?.value && (
                  <Col md={6}>
                    <Field
                      name={`${groupName}membership.cancellationDate`}
                      component={TextField}
                      label="Cancellation Date"
                      placeholder="mm/dd/yyyy"
                      inputClassName="form-control-lg date-field"
                      disabled
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <Field
                    name={`${groupName}membership.organization`}
                    component={SelectList}
                    customStyles={getLgSelectStyle}
                    label={
                      <>
                        Organization{" "}
                        {organizationOptions.loading && (
                          <span className="small">(Searching...)</span>
                        )}
                      </>
                    }
                    placeholder="Type to search"
                    options={organizationOptions.data.map((org) => ({
                      label: org.name,
                      value: org,
                    }))}
                    onChange={onOrgChange}
                    onInputChange={(value, { action }) => {
                      if (action === "input-change") {
                        onInputChange && onInputChange(value);
                      }
                    }}
                    inputClassName="form-control-lg"
                    searchable={!Boolean(selectedOrganization)}
                    disabled={disableOrganization}
                    isClearable
                    showErrors={showErrors}
                  />
                </Col>
              </Row>
              <Row className="mb-3 mt-2">
                {selectedOrganization &&
                  !Boolean(
                    selectedOrganization.requiresRecuroSubscriberNumber
                  ) && (
                    <Col md={6}>
                      <span className="font-weight-bold">Member ID:</span>{" "}
                      {subscriberNumber
                        ? subscriberNumber
                        : "assigned by the system"}
                    </Col>
                  )}
                <Col md={6}>
                  <span className="font-weight-bold">Product Code:</span>{" "}
                  {selectedOrganization
                    ? selectedOrganization.productCode
                    : "assigned by the organization"}
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <span className="font-weight-bold">APS Plan ID:</span>{" "}
                  {selectedOrganization
                    ? selectedOrganization.apsPlanId
                    : "assigned by the organization"}
                </Col>
                <Col md={6}>
                  <span className="font-weight-bold">APS Group ID:</span>{" "}
                  {selectedOrganization
                    ? selectedOrganization.apsGroupId
                    : "assigned by the organization"}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <span className="font-weight-bold">Elixir Group ID:</span>{" "}
                  {selectedOrganization
                    ? selectedOrganization.elixirGroupId
                    : "assigned by the organization"}
                </Col>
                <Col md={6}>
                  <span className="font-weight-bold">Recuro Group ID:</span>{" "}
                  {selectedOrganization
                    ? selectedOrganization.recuroGroupId
                    : "assigned by the organization"}
                </Col>
              </Row>
            </div>
          </div>
          <div className="card">
            <div className="card-header bg-transparent d-flex justify-content-between">
              <div className="my-auto contacts__cardTitle">
                <strong>Primary Member</strong>
              </div>
            </div>
            <div className="card-body">
              <Row>
                <Col xl={7}>
                  <Row>
                    <Col md={6}>
                      <Field
                        name={`${groupName}primaryMember.firstName`}
                        component={TextField}
                        label="First Name"
                        placeholder="First Name"
                        lengthClassName="small"
                        inputClassName="form-control-lg"
                        showErrors={showErrors}
                        maxLength={50}
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name={`${groupName}primaryMember.lastName`}
                        component={TextField}
                        label="Last Name"
                        placeholder="Last Name"
                        lengthClassName="small"
                        inputClassName="form-control-lg"
                        showErrors={showErrors}
                        maxLength={50}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Field
                        name={`${groupName}primaryMember.dateOfBirth`}
                        component={TextField}
                        inputMode="numeric"
                        label="Date of Birth"
                        placeholder="mm/dd/yyyy"
                        inputClassName="form-control-lg date-field"
                        showErrors={showErrors}
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name={`${groupName}primaryMember.gender`}
                        component={SelectList}
                        customStyles={getLgSelectStyle}
                        label="Gender"
                        placeholder="Gender"
                        options={genderOptions}
                        searchable={false}
                        inputClassName="form-control-lg"
                        showErrors={showErrors}
                      />
                    </Col>
                  </Row>
                  <AddressFieldGroup
                    googleMapsLoaded={true}
                    groupName={`${groupName}primaryMember`}
                    addressValue={
                      state[`${groupName}primaryMember.address`].value as string
                    }
                    onClear={actions.clearAddress}
                    usStates={usStates}
                    onSelect={actions.selectPlace}
                    lgStyle
                    showErrors={showErrors}
                  />
                  <Row>
                    <Col md={6}>
                      <Field
                        name={`${groupName}primaryMember.phoneNumber`}
                        component={PhoneInput}
                        label="Phone Number"
                        placeholder="XXX-XXX-XXXX"
                        inputClassName="form-control-lg"
                        showErrors={showErrors}
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name={`${groupName}primaryMember.email`}
                        component={EmailInput}
                        label="Email Address"
                        inputClassName="form-control-lg"
                        showErrors={showErrors}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <FormArray name={`${groupName}dependents`}>
            {({ items, removeControl }) => (
              <>
                {items.map(
                  (
                    dependentControl: ControlModels.FormControl<Dependent>,
                    index
                  ) => {
                    const { key } = dependentControl;

                    return (
                      <Card className="mt-4" key={key}>
                        <Card.Header className="bg-transparent d-flex justify-content-between">
                          <div className="my-auto contacts__cardTitle">
                            <strong>Dependent #{index + 1}</strong>
                          </div>
                          <button
                            className="btn-link--black btn-link--delete btn btn-link"
                            onClick={() => removeControl(index)}
                          >
                            Delete
                          </button>
                        </Card.Header>
                        <Card.Body>
                          <DependentFields
                            dependentControl={dependentControl}
                            dependentDobControl={
                              state[
                                `dependents.${index}.dateOfBirth`
                              ] as ControlModels.FormControl<string>
                            }
                            groupName={`dependents.${index}`}
                            disableSpouseRadio={disableSpouseRadio}
                            relationshipControl={
                              state[
                                `dependents.${index}.relationshipToAccountHolder`
                              ] as ControlModels.FormControl<RelationshipType>
                            }
                            customStyles={getLgSelectStyle}
                            showErrors={showErrors}
                          />
                        </Card.Body>
                      </Card>
                    );
                  }
                )}
                {dependents.length < MAX_DEPENDENTS && (
                  <Button
                    text="Add Dependent"
                    className="btn btn-outline-secondary w-100 mt-4"
                    onClick={actions.addDependent}
                  ></Button>
                )}
              </>
            )}
          </FormArray>
        </div>
      </Form>
    </>
  );
};

export default MemberForm;
