import { ofTypes, Reactable } from "@reactables/core";
import { combineLatest, from, of, tap } from "rxjs";
import { map } from "rxjs/operators";
import { RxRequest, RequestState, RequestActions } from "@jauntin/reactables";
import {
  PromoCodeDetails,
  PromoCodePayload,
} from "@basicare/common/src/Models/promoCode.model";
import PromoCodeService from "Services/PromoCodeService";
import { HookedReactable } from "@reactables/react";
import { sendOnSuccess } from "@basicare/common/src/Rx/Operators/sendOnSuccess.operator";

export interface PromoCodeState {
  promoCode: RequestState<PromoCodeDetails>;
  editRequest: RequestState<unknown>;
  addNoteRequest: RequestState<unknown>;
}

export interface PromoCodeActions {
  edit: RequestActions<PromoCodePayload>;
  addNote: RequestActions<{ id: number; message: string }>;
}

export type PromoCodeProp = HookedReactable<PromoCodeState, PromoCodeActions>;

export const RxPromoCode = ({
  promoCodeService,
  id,
  onEditSuccess,
}: {
  promoCodeService: PromoCodeService;
  id: number;
  onEditSuccess?: () => void;
}): Reactable<PromoCodeState, PromoCodeActions> => {
  const [editRequest$, editActions, editActions$] = RxRequest<
    PromoCodePayload,
    unknown
  >({
    name: "rxEditPromoCode",
    effect: (action$) =>
      action$.pipe(
        map(({ payload }) =>
          from(promoCodeService.putPromoCodeEditDetails(payload, id)).pipe(
            tap(() => {
              onEditSuccess && onEditSuccess();
            })
          )
        )
      ),
  });

  const fetchOnEditSuccess$ = editActions$.pipe(sendOnSuccess);

  const [addNoteRequest$, addNoteActions, addNoteActions$] = RxRequest<
    { id: number; message: string },
    unknown
  >({
    name: "rxAddNoteRequest",
    effect: (action$) =>
      action$.pipe(
        map(({ payload }) => from(promoCodeService.postAddNote(payload)))
      ),
  });

  const fetchOnAddNoteSuccess$ = addNoteActions$.pipe(sendOnSuccess);

  const [promoCode$] = RxRequest<undefined, PromoCodeDetails>({
    name: "rxLoadPromoCode",
    sources: [
      of({ type: "send" }),
      fetchOnEditSuccess$,
      fetchOnAddNoteSuccess$,
    ],
    effect: (action$) =>
      action$.pipe(
        map(() =>
          from(promoCodeService.getPromoCode(id)).pipe(map(({ data }) => data))
        )
      ),
  });

  const state$ = combineLatest({
    promoCode: promoCode$,
    editRequest: editRequest$,
    addNoteRequest: addNoteRequest$,
  });

  const actions = {
    edit: editActions,
    addNote: addNoteActions,
  };

  const addNoteResponse$ = addNoteActions$.pipe(
    ofTypes(["sendSuccess", "sendFailure"]),
    map(({ type }) => ({ type: `${type}AddNote` }))
  );

  return [state$, actions, addNoteResponse$];
};
