import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { Gender } from "@basicare/common/src/Constants/genders";
import { MemberStatuses } from "@basicare/common/src/Constants/memberStatuses";
import { PlanTypes } from "@basicare/common/src/Constants/planTypes";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { NoteTypes } from "@jauntin/react-ui";

export interface MemberDetails {
  id: number;
  subscriberNumber: string;
  subscriptionInterval: PlanTypes;
  recuroSubscriberNumber: any;
  effectiveDate: string;
  firstName: string;
  lastName: string;
  gender: Gender;
  dateOfBirth: string;
  email: string;
  phone: string;
  status: MemberStatuses;
  organization: OrganizationDetails;
  primaryAddress: PrimaryAddress;
  subscription: Subscription | null;
  contacts: CustomContact[];
  dependents: Dependent[];
  notes: {
    id: number;
    message: string;
    type: NoteTypes;
    user?: {
      name: string;
      email: string;
    };
    createdAt: string;
    updatedAt: string;
  }[];
  isInTrial: boolean;
  trial?: {
    active: boolean;
    type: PromoCodeTypes;
  };
  promoCode?: {
    id: number;
    name: string;
    type: PromoCodeTypes;
    discount?: {
      type: DiscountTypes;
      amount: number;
    };
    startDate: string;
    endDate?: string;
  };
  createdAt: string;
  cancellationDate?: string;
}

export interface MemberSearchDetails {
  id: number;
  subscriberNumber: string;
  subscriptionInterval: PlanTypes;
  recuroSubscriberNumber: any;
  firstName: string;
  lasttName: string;
  email: string;
  phone: string;
  organization: {
    name: string;
    requiresRecuroSubscriberNumber: boolean;
  };
  isInTrial: boolean;
}

export interface CustomContact {
  id?: number;
  fullName: string;
  email: string;
}

export const emptyCustomContact: CustomContact = {
  id: null,
  fullName: "",
  email: "",
};

interface PrimaryAddress {
  address1: string;
  address2: any;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export enum SubscriptionStatus {
  ACTIVE = "Active",
  CANCELED = "Canceled",
  EXPIRED = "Expired",
  PAST_DUE = "Past Due",
  PENDING = "Pending",
}

interface Subscription {
  id: number;
  status: SubscriptionStatus;
  paidThroughDate: string;
  nextBillingDate: string;
  creditCardLastFour: string;
  creditCardType: string;
  creditCardNameOnCard: string;
  nameOnCard: string;
}

interface OrganizationDetails {
  id: number;
  code: string;
  name: string;
  productCode: string;
  apsPlanId: string;
  apsGroupId: string;
  elixirGroupId: string;
  recuroGroupId: string;
  recuroAgentId: string;
  hideFacilityInfo: boolean;
  producer: Producer;
  facilityProducerContacts: FacilityProducerContact[];
  contacts: FacilityContact[];
  status: string;
  createdAt: string;
  updatedAt: string;
  logoUrl: any;
  notes: FacilityNote[];
  additionalInsuredAddress: AdditionalInsuredAddress;
  links: Links;
  requiresRecuroSubscriberNumber: boolean;
  doNotSendEmailsToMember: boolean;
  monthlyPrice: number;
}

interface Producer {
  id: number;
  name: string;
  commissionId: string;
  logoUrl: any;
  createdAt: string;
  updatedAt: string;
  status: string;
}

interface FacilityProducerContact {
  id: number;
  fullName: string;
  email: string;
}

interface FacilityContact {
  id: number;
  facilityId: number;
  fullName: string;
  role: string;
  copyOnEmails: boolean;
  createdAt: string;
  updatedAt: string;
  email: string;
}

interface FacilityNote {
  id: number;
  message: string;
  type: string;
  user?: User;
  createdAt: string;
  updatedAt: string;
}

interface User {
  name: string;
  email: string;
}

interface AdditionalInsuredAddress {
  id: number;
  companyName: string;
}
interface PrimaryAddress {
  address1: string;
  address2: any;
  city: string;
  state: string;
  zip: string;
  country: string;
  timezone?: string;
}

interface Links {
  facilityReferralLink: string;
}
