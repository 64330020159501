import API from "../Services/API";
import MembershipService from "../Services/MembershipService";
import download from "downloadjs";
import { dateHelpers } from "@jauntin/utilities";

export const sendReportByType = (type) =>
  new MembershipService(new API()).sendReportByType(type).then(() => {
    console.log("Report sent");
  });

export const downloadMembershipReport = (
  startDate,
  endDate,
  timezone,
  type
) => {
  const format = type === "APSMembershipReport" ? "txt" : "csv";

  return new MembershipService(new API())
    .downloadMemberReport(
      startDate,
      endDate,
      timezone,
      type,
      type === "APSMembershipReport" ? "ApsTsvAdapter" : "CsvAdapter"
    )
    .then(({ data }) =>
      download(
        data,
        `${type} ${
          startDate
            ? dateHelpers.dateOnlyStringFormat(startDate, timezone) + ","
            : ""
        }${dateHelpers.dateOnlyStringFormat(endDate, timezone)}.${format}`,
        `text/${format}`
      )
    );
};
