import { useReactable } from "@reactables/react";
import { RxToggle } from "@jauntin/reactables";
import { Link } from "react-router-dom";
import { EDIT_PROMO_CODE_PAGE, getUrl } from "Helpers/URLParser";
import { PromoCodeProp } from "Features/PromoCodes/Rx/RxPromoCode";
import { Button, NotesContainer } from "@jauntin/react-ui";
import { Card, Row, Col } from "react-bootstrap";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import copyToClipboard from "../../../Helpers/CopyToClipboard";
import ReferralLinkToast from "../../Shared/Components/ReferralLinkToast";
import { useNotePublisher } from "Features/Shared/Hooks/useNotePublisher";
import PromoCodeReferral from "./PromoCodeReferral";
import { getPromoCodeTypeText } from "@basicare/common/src/Constants/promoCodeTypes";

const PromoCodeView = ({ rxPromoCode }: { rxPromoCode: PromoCodeProp }) => {
  const [
    {
      addNoteRequest,
      promoCode: {
        data: {
          id,
          name,
          code,
          subscriptionInterval,
          type,
          discountType,
          discountAmount,
          billingCycles,
          usages,
          startDate,
          endDate,
          redeems,
          notes,
        },
      },
    },
    actions,
    ,
    actions$,
  ] = rxPromoCode;

  const [showCopyToast, { toggleOn, toggleOff }] = useReactable(RxToggle);

  const publish = useNotePublisher({ actions$, publish: actions.addNote.send });

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Promo Code</h4>
          <div className="d-flex float-right">
            <Link to={getUrl(EDIT_PROMO_CODE_PAGE, id)}>
              <Button
                text="Edit Promo Code"
                className="btn btn-primary px-4 mx-2 text-nowrap"
                onClick={() => {}}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="content__body">
        <PromoCodeReferral
          referralLink={`${process.env.REACT_APP_FRONTEND_LOCATION}?promo=${code}`}
        />
        <Card className="mb-4">
          <Card.Header className="bg-white font-weight-bold">
            General Information
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Promo Code Name:</span>{" "}
                {name}
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Promo Code ID:</span> {code}
                <button
                  className="undecorate-btn ml-2"
                  onClick={() => {
                    copyToClipboard(code);
                    toggleOn();
                  }}
                >
                  <i className="fal fa-copy" />
                </button>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Subscription Interval:</span>{" "}
                <span className="text-capitalize">{subscriptionInterval}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Promo Code Type:</span>{" "}
                <span>{getPromoCodeTypeText(type)}</span>
              </Col>
            </Row>
            {discountAmount && (
              <Row className="mb-3">
                <Col md={6}>
                  <span className="font-weight-bold">Discount Amount:</span>{" "}
                  <span className="text-capitalize">
                    {discountType === DiscountTypes.Fixed
                      ? currencyFromInt(discountAmount).formatDollars()
                      : `${discountAmount} %`}
                  </span>
                </Col>
              </Row>
            )}

            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Billing Cycles:</span>{" "}
                <span>
                  {billingCycles === null ? "unlimited" : billingCycles}
                </span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Number of Usages:</span>{" "}
                <span>{usages === null ? "unlimited" : usages}</span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <span className="font-weight-bold">Start Date:</span>{" "}
                <span>{startDate}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">End Date:</span>{" "}
                <span>{endDate}</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Row className="mb-3">
          <Col md={6}>
            <span className="font-weight-bold">Current Usage Count:</span>{" "}
            <span className="text-capitalize">{redeems}</span>
          </Col>
        </Row>
        <Card>
          <NotesContainer
            publish={publish}
            entityId={id}
            notes={notes.map(({ id, message, type, user, createdAt }) => ({
              id,
              message,
              type,
              userName: user?.name,
              created: createdAt,
            }))}
            publishing={addNoteRequest.loading}
          />
        </Card>
      </div>

      <ReferralLinkToast
        show={showCopyToast || false}
        setShowToast={toggleOff}
        text="Promo code"
      />
    </>
  );
};

export default PromoCodeView;
