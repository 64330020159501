import { Reactable, ActionMap, Action } from "@reactables/core";
import { RxFormActions, ControlModels } from "@reactables/forms";
import { of, combineLatest, Observable, from } from "rxjs";
import { mergeMap, map, catchError, tap } from "rxjs/operators";
import { Reducers, RxBuilder } from "@jauntin/reactables";
import { AddMemberPayload, Member } from "./Models/member.model";
import { Organization } from "@basicare/common/src/Models/organization.model";
import FacilityService from "Services/FacilityService";
import { OrganizationTypeaheadActions } from "./RxOrganizationTypeahead";
import MembershipService from "Services/MembershipService";
import { MemberFormActions, RxMemberForm } from "./RxMemberForm";
import { RxOrganizationTypeahead } from "./RxOrganizationTypeahead";

export type AddMemberActions = {
  addMemberSubmission: {
    addMember: (member: AddMemberPayload) => void;
    resetSubmissionState: () => void;
  };
  form: MemberFormActions & RxFormActions & ActionMap;
} & OrganizationTypeaheadActions;

export interface AddMemberState {
  form: ControlModels.Form<Member>;
  organizationTypeahead: Reducers.LoadableState<Organization[]>;
  addMemberSubmission: Reducers.LoadableState<null>;
}

const { loadableInitialState, load, loadError, loadSuccess } = Reducers;

export const RxAddMember = ({
  facilityService,
  membershipService,
  onAddMemberSuccess,
}: {
  facilityService: FacilityService;
  membershipService: MembershipService;
  onAddMemberSuccess: () => void;
}): Reactable<AddMemberState, AddMemberActions> => {
  const [form$, formActions] = RxMemberForm(membershipService);

  const [organizationTypeahead$, { searchOrganizations }] =
    RxOrganizationTypeahead({ facilityService });

  const [addMemberSubmission$, addMemberSubmissionActions] = RxBuilder({
    name: "rxAddMemberSubmission",
    initialState: loadableInitialState,
    reducers: {
      addMember: {
        reducer: load,
        effects: [
          (memberAction$: Observable<Action<AddMemberPayload>>) =>
            memberAction$.pipe(
              mergeMap(({ payload }) =>
                from(membershipService.addMember(payload)).pipe(
                  map(() => ({ type: "addMemberSuccess" })),
                  tap(() => onAddMemberSuccess()),
                  catchError(() =>
                    of({
                      type: "addMemberFailure",
                      payload: true,
                    })
                  )
                )
              )
            ),
        ],
      },
      addMemberSuccess: loadSuccess,
      addMemberFailure: loadError,
      resetSubmissionState: () => loadableInitialState,
    },
  });

  const state$ = combineLatest({
    form: form$ as Observable<ControlModels.Form<Member>>,
    organizationTypeahead: organizationTypeahead$,
    addMemberSubmission: addMemberSubmission$,
  });

  const actions = {
    form: formActions,
    searchOrganizations,
    addMemberSubmission: addMemberSubmissionActions as ActionMap,
  } as AddMemberActions;

  return [state$, actions];
};
