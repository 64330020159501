import { from, of } from "rxjs";
import { mergeMap, catchError, tap } from "rxjs/operators";
import { Action } from "@reactables/core";
import { Reducers, RxBuilder } from "@jauntin/reactables";
import MembershipService from "Services/MembershipService";
import { SaveMemberPayload } from "./Models/member.model";

const { loadableInitialState, load, loadSuccess, loadError } = Reducers;

export interface SaveMemberActions {
  saveMember: (member: SaveMemberPayload) => void;
  saveMemberReset: () => void;
}

export type SaveMemberState = Reducers.LoadableState<unknown>;

export const RxSaveMember = ({
  id,
  membershipService,
  onSaveMemberSuccess,
}: {
  id: number;
  membershipService: MembershipService;
  onSaveMemberSuccess?: () => void;
}) =>
  RxBuilder({
    name: "rxSaveMember",
    initialState: loadableInitialState,
    reducers: {
      saveMember: {
        reducer: load,
        effects: [
          (action$) =>
            action$.pipe(
              mergeMap(({ payload }: Action<SaveMemberPayload>) => {
                return from(membershipService.saveMember(payload, id)).pipe(
                  tap(() => {
                    onSaveMemberSuccess && onSaveMemberSuccess();
                  }),
                  mergeMap(() =>
                    of(
                      { type: "saveMemberSuccess" },
                      { type: "saveMemberReset" }
                    )
                  ),
                  catchError(() =>
                    of({ type: "saveMemberFailure", payload: true })
                  )
                );
              })
            ),
        ],
      },
      saveMemberSuccess: loadSuccess,
      saveMemberFailure: loadError,
      saveMemberReset: () => loadableInitialState,
    },
  });
