import { OperatorFunction } from "rxjs";
import { ofTypes, Action } from "@reactables/core";
import { map } from "rxjs/operators";

export const sendOnSuccess: OperatorFunction<
  Action<unknown>,
  Action<unknown>
> = (actions$) =>
  actions$.pipe(
    ofTypes(["sendSuccess"]),
    map(() => ({ type: "send" }))
  );
