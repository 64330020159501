import { useReactable } from "@reactables/react";
import { RxToggle } from "@jauntin/reactables";
import { ExtendedMeta } from "@jauntin/utilities";
import { Button } from "@jauntin/react-ui";
import { useHistory } from "react-router-dom";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { PROMO_CODES_PAGE, getUrl } from "Helpers/URLParser";
import ModalDiscardAddNew from "Features/Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "Features/Shared/Components/ModalUpdateError";
import { RxAddPromoCode } from "Features/PromoCodes/Rx/RxAddPromoCode";
import PromoCodeForm from "./PromoCodeForm";
import PromoCodeService from "Services/PromoCodeService";
import API from "Services/API";
import { getAddPromoActionEnabled } from "Features/PromoCodes/Rx/Selectors/promoCode.selectors";

const AddPromoCode = () => {
  const history = useHistory();

  const goToPromoCodesPage = () => history.push(getUrl(PROMO_CODES_PAGE));

  const [submitTouched, { toggleOn: touchSubmit }] = useReactable(RxToggle);
  const [showDiscardModal, discardModalToggle] = useReactable(RxToggle);

  const [state, actions] = useReactable(RxAddPromoCode, {
    promoCodeService: new PromoCodeService(new API()),
    onAddPromoCodeSuccess: goToPromoCodesPage,
  });

  if (!state) return;

  const actionEnabled = getAddPromoActionEnabled(state, submitTouched);

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || submitTouched) && error);

  return (
    <div className="scroll-part">
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add New Promo Code</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={discardModalToggle.toggleOn}
            />
            <TwoStageButton
              className="btn btn-primary px-4 mx-2 text-nowrap border-radius-0"
              spinning={state.submission.loading}
              actionEnabled={actionEnabled}
              action={() => actions.submission.send(state.form.root.value)}
              touchSubmit={touchSubmit}
              text="Save Promo Code"
              disableAction={!state.form.root.valid}
            />
          </div>
        </div>
      </div>

      <PromoCodeForm
        form={[state.form, actions.form]}
        showErrors={showErrors}
        generatePromoCodeRequest={[
          state.generatePromoCodeRequest,
          actions.generatePromoCode,
        ]}
      />

      <ModalDiscardAddNew
        show={showDiscardModal}
        handleClose={discardModalToggle.toggleOff}
        confirmAction={goToPromoCodesPage}
      />

      <ModalUpdateError
        show={Boolean(state.submission.error)}
        text="There was an error creating promo code. Please try again"
        handleCloseError={actions.submission.resetState}
      />
    </div>
  );
};

export default AddPromoCode;
