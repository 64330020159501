import { useEffect, useState, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormValues,
  reduxForm,
  reset,
  change,
} from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { CheckboxInput, ConnectedRouter } from "@jauntin/react-ui";
import { maxFacilityCodeLength } from "@basicare/common/src/Constants/codes";
import { Button } from "@jauntin/react-ui";
import {
  ACTIVE,
  addFacilityForm,
  alertNew,
  DEFAULT_APS_GROUP_ID,
  DEFAULT_APS_PLAN_ID,
  DEFAULT_ELIXIR_GROUP_ID,
  modalUpdateServerErrorMessage,
  producerContactsPropType,
  producersPropType,
  statePropType,
} from "../../../constants";
import {
  facilityCodeValidation,
  contactEmailsUnique,
  facilityOtherAdditionalInsuredZipCode,
  facilityProducer,
  priceFormat,
} from "../../../Helpers/validators";
import Producer from "./FormElements/Producer";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import FacilityCodeField from "./FormElements/FacilityCodeField";
import {
  normalizeZip,
  normalizeFacilityCode,
  normalizeAlphanumeric,
} from "../../../normalizer";
import {
  ADD_PRODUCER_PAGE,
  FACILITIES_PAGE,
  getUrl,
} from "../../../Helpers/URLParser";
import {
  searchProducersForFacility,
  checkIsValidFacilityCode,
  setSelectedProducerInResults,
  setNewFacilityStatusMessage,
  errorResponse,
  setProducerSearchResults,
  showFacilityStatusAlert,
  setGeneratedFacilityCode,
} from "../../../Redux/Actions/actions";
import Debounce from "../../../Helpers/Debounce";
import FacilityService from "../../../Services/FacilityService";
import API from "../../../Services/API";
import CountryField from "./FormElements/CountryField";
import ModalDiscardAddNew from "../../Shared/Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Shared/Components/ModalUpdateError";
import ContactFields from "./FormElements/ContactFields";
import ProducerContacts from "./FormElements/ProducerContactsField";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import ProductCodeField from "./FormElements/ProductCodeField";
import { validators } from "@jauntin/utilities";
import FacilityLogoUpload from "./FacilityLogoUpload";
import { getAddFacilityFormImgSrc } from "Redux/Selectors/Facility";
import { fileToBase64 } from "@basicare/common/src/Helpers/Base64EncodeFile";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import APSPlanIdField from "./FormElements/APSPlanIdField";
import APSGroupIdField from "./FormElements/APSGroupIdField";
import ElixirGroupIdField from "./FormElements/ElixirGroupIdField";
import {
  recuroGroupId,
  normalizePrice,
} from "@basicare/common/src/Helpers/bcxNormalizers";

const { required, alphaNumeric, maxLength } = validators;
const maxLength20 = maxLength(20);

const debouncer = new Debounce({ period: 500 });
const allFormValues = getFormValues(addFacilityForm);
const formValues = formValueSelector(addFacilityForm);

const SectionHeader = ({ text }) => (
  <div className="card-header bg-transparent">
    <strong>{text}</strong>
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

const AddFacility = ({
  pristine,
  valid,
  states,
  goToSearchPage,
  checkAndSetValidFacilityCode,
  getProducers,
  producersList,
  producerContacts,
  setSelectedProducerResult,
  addFacility,
  commissionRateOptions,
  facilityCodeCounter,
  selectFacilityLogo,
  clearFacilityLogo,
  facilityLogoSrc,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [validFacilityCode, setValidFacilityCode] = useState(true);
  const [hasCheckedFacilityCode, setHasCheckedFacilityCode] = useState(false);

  const stateOptions = useMemo(() => {
    return states.map((usState) => ({
      label: usState.name,
      value: usState.code,
    }));
  }, [states]);

  // Clears brokers search results on page load
  useEffect(() => {
    setSelectedProducerResult("");
  }, [setSelectedProducerResult, commissionRateOptions]);

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add New Organization</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-2 px-lg-4 mx-2"
              onClick={handleShow}
            />
            <Button
              text="Save Organization"
              className="btn btn-primary px-4 mx-2 text-nowrap"
              onClick={() => addFacility(handleShowError)}
              disabled={
                pristine ||
                !valid ||
                !validFacilityCode ||
                !hasCheckedFacilityCode
              }
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="card w-100">
          <SectionHeader text="Organization Information" />
          <div className="card-body">
            <Row>
              <Col md={7}>
                <Field
                  component={FacilityCodeField}
                  entityType="Organization"
                  validate={[required, facilityCodeValidation]}
                  name="facilityCode"
                  type="text"
                  maxLength={maxFacilityCodeLength}
                  normalize={normalizeFacilityCode}
                  onChange={(e) => {
                    const normalizeValue = normalizeFacilityCode(
                      e.target.value
                    );

                    if (
                      normalizeValue &&
                      normalizeValue.length <= maxFacilityCodeLength
                    ) {
                      setHasCheckedFacilityCode(false);
                      debouncer.do(
                        checkAndSetValidFacilityCode,
                        normalizeValue,
                        setHasCheckedFacilityCode,
                        setValidFacilityCode
                      );
                    }
                  }}
                  hasCheckedFacilityCode={hasCheckedFacilityCode}
                  validFacilityCode={validFacilityCode}
                />
                <div className="text-right policy--charCount">
                  {facilityCodeCounter}/{maxFacilityCodeLength} characters
                </div>
                {hasCheckedFacilityCode && !validFacilityCode && (
                  <div className="form-row">
                    <div className="col-sm form-group form-error">
                      The Organization Code has already been taken.
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={7}>
                <Field
                  component={TextField}
                  validate={[required]}
                  label="Organization Name"
                  ariaLabel="Organization Name"
                  name="facilityName"
                  type="text"
                  inputClassName="form-control-lg col-lg-6"
                  errorClassName="d-inline ml-2"
                />
                <Field
                  component={TextField}
                  validate={[required, priceFormat]}
                  normalize={normalizePrice}
                  label="Monthly Price"
                  ariaLabel="Monthly Price"
                  name="monthlyPrice"
                  type="text"
                  inputClassName="form-control-lg col-lg-6"
                  errorClassName="d-inline ml-2"
                />
                <div className="form-row">
                  <Col mb={6}>
                    <Field
                      component={ProductCodeField}
                      validate={[required]}
                      name="productCode"
                    />
                  </Col>
                  <Col mb={6}>
                    <Field
                      component={TextField}
                      validate={[alphaNumeric]}
                      normalize={normalizeAlphanumeric(255)}
                      label="Recuro Agent ID"
                      ariaLabel="Recuro Agent ID"
                      name="recuroAgentId"
                      type="text"
                      inputClassName="form-control-lg"
                      errorClassName="d-inline ml-2"
                    />
                  </Col>
                </div>
                <div className="form-row">
                  <Col mb={6}>
                    <Field
                      component={APSPlanIdField}
                      validate={[required]}
                      name="apsPlanId"
                    />
                  </Col>
                  <Col mb={6}>
                    <Field
                      component={APSGroupIdField}
                      validate={[required]}
                      name="apsGroupId"
                    />
                  </Col>
                </div>
                <div className="form-row">
                  <Col mb={6}>
                    <Field
                      component={ElixirGroupIdField}
                      validate={[required]}
                      name="elixirGroupId"
                    />
                  </Col>
                  <Col mb={6}>
                    <Field
                      component={TextField}
                      inputClassName="form-control-lg"
                      label="Recuro Group ID"
                      name="recuroGroupId"
                      validate={[required, alphaNumeric, maxLength20]}
                      normalize={recuroGroupId}
                      errorClassName="d-inline ml-2"
                    />
                  </Col>
                </div>
                <Field
                  component={CheckboxInput}
                  name="requiresRecuroSubscriberNumber"
                  type="checkbox"
                  className="d-flex align-items-center"
                  ariaLabel="Requires Recuro Subscriber Number"
                  labelClassName="pl-2"
                  label="Requires Recuro Subscriber Number"
                />
                <Field
                  component={CheckboxInput}
                  name="doNotSendEmailsToMember"
                  type="checkbox"
                  className="d-flex align-items-center"
                  ariaLabel="Do not send emails to the member"
                  labelClassName="pl-2"
                  label="Do not send emails to the member"
                />
                <Field
                  component={TextField}
                  name="otherInsured.address1"
                  type="text"
                  label="Street address"
                  ariaLabel="Street address"
                  inputClassName="form-control-lg mb-2"
                  className="mb-0"
                />
                <Field
                  component={TextField}
                  name="otherInsured.address2"
                  type="text"
                  ariaLabel="Address Line 2"
                  inputClassName="form-control-lg mb-2"
                />
                <div className="form-row">
                  <div className="col-sm">
                    <Field
                      component={TextField}
                      name="otherInsured.city"
                      type="text"
                      label="City"
                      ariaLabel="City"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      name="otherInsured.state"
                      component={SelectList}
                      customStyles={getLgSelectStyle}
                      label="State"
                      placeholder="State"
                      options={stateOptions}
                      isClearable={true}
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      component={TextField}
                      validate={[facilityOtherAdditionalInsuredZipCode]}
                      normalize={normalizeZip}
                      name="otherInsured.zip"
                      type="text"
                      label="Zipcode"
                      ariaLabel="Zip"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div hidden>
                    <Field
                      name="otherInsured.country"
                      component={CountryField}
                      type="select"
                      input={{ disabled: true }}
                      hidden="true"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <FacilityLogoUpload
              imgSrc={facilityLogoSrc}
              onSelect={selectFacilityLogo}
              onClear={clearFacilityLogo}
            />
          </div>
        </div>

        <div className="card mt-4">
          <SectionHeader text="Broker" />
          <div className="card-body">
            <Row>
              <Col md={7}>
                <Field
                  component={Producer}
                  validate={[facilityProducer]}
                  name="producer"
                  type="text"
                  getProducers={getProducers}
                  producersList={producersList}
                  setResults={setSelectedProducerResult}
                  rateOptions={commissionRateOptions}
                />
                <div className="mb-4">
                  Not finding the broker&apos;s name or code?
                  <a
                    href={getUrl(ADD_PRODUCER_PAGE)}
                    className="font-weight-normal ml-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Create a new broker
                  </a>
                </div>

                {producerContacts.length > 0 && (
                  <FieldArray
                    name="producer.producerContacts"
                    component={ProducerContacts}
                    producerContacts={producerContacts}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>

        <FieldArray name="contacts" component={ContactFields} />
      </div>

      <ModalDiscardAddNew
        show={showModal}
        handleClose={handleClose}
        confirmAction={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

AddFacility.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  getProducers: PropTypes.func.isRequired,
  checkAndSetValidFacilityCode: PropTypes.func.isRequired,
  producersList: PropTypes.arrayOf(producersPropType),
  setSelectedProducerResult: PropTypes.func.isRequired,
  addFacility: PropTypes.func.isRequired,
  producerContacts: PropTypes.arrayOf(producerContactsPropType),
  commissionRateOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  facilityCodeCounter: PropTypes.number.isRequired,
  selectFacilityLogo: PropTypes.func.isRequired,
  clearFacilityLogo: PropTypes.func.isRequired,
  facilityLogoSrc: PropTypes.string,
};

AddFacility.defaultProps = {
  producersList: [],
  producerContacts: [],
};

const mapStateToProps = (state) => {
  const hasProducerContacts =
    state.form.addFacility.values &&
    state.form.addFacility.values.producer &&
    state.form.addFacility.values.producer.producerContacts;
  return {
    states: state.app.states,
    producersList: state.facilities.producersSearchResults,
    producerContacts: hasProducerContacts
      ? state.form.addFacility.values.producer.producerContacts
      : [],
    commissionRateOptions: state.facilities.commissionRates,
    facilityCodeCounter: formValues(state, "facilityCode")?.length || 0,
    facilityLogoSrc: getAddFacilityFormImgSrc(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: () => {
    dispatch(reset(addFacilityForm));
    dispatch(ConnectedRouter.push(getUrl(FACILITIES_PAGE)));
  },
  getProducers: (inputValue) =>
    debouncer.do(
      (searchInput) => dispatch(searchProducersForFacility(searchInput)),
      inputValue
    ),
  checkAndSetValidFacilityCode: (
    id,
    setHasCheckedCommissionId,
    setValidCommissionId
  ) =>
    dispatch(
      checkIsValidFacilityCode(
        id,
        setHasCheckedCommissionId,
        setValidCommissionId
      )
    ),
  setSelectedProducerResult: (producer) => {
    dispatch(setSelectedProducerInResults(producer));
  },
  addFacility: (goToSearchPage, handleShowError) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const emailProducerContacts = values.producer.producerContacts.filter(
        (contact) => contact.copyOnEmails
      );
      const data = {
        status: ACTIVE, // TODO: temporary, anticipate status selector in future
        name: values.facilityName,
        code: values.facilityCode,
        hideFacilityInfo: values.hideFacilityInfo,
        otherInsured: values.otherInsured,
        producerId: values.producer.value,
        emailProducerContacts,
        contacts: values.contacts,
        productCode: values.productCode,
        recuroAgentId: values.recuroAgentId,
        apsPlanId: values.apsPlanId,
        apsGroupId: values.apsGroupId,
        elixirGroupId: values.elixirGroupId,
        recuroGroupId: values.recuroGroupId,
        requiresRecuroSubscriberNumber: values.requiresRecuroSubscriberNumber,
        doNotSendEmailsToMember: values.doNotSendEmailsToMember,
        monthlyPrice: values.monthlyPrice,
      };

      let createFacilityPromise;

      if (values.facilityImgFile) {
        createFacilityPromise = fileToBase64(values.facilityImgFile).then(
          (content) => {
            const logoImageFile = {
              fileName: values.facilityImgFile.name,
              size: values.facilityImgFile.size,
              content,
            };

            return new FacilityService(new API()).postAddNewFacilityDetails({
              ...data,
              logoImageFile,
            });
          }
        );
      } else {
        createFacilityPromise = new FacilityService(
          new API()
        ).postAddNewFacilityDetails(data);
      }

      return createFacilityPromise
        .then((response) => {
          if (response.status === 201) {
            dispatch(showFacilityStatusAlert(true));
            dispatch(setNewFacilityStatusMessage(alertNew("Organization")));
            dispatch(setProducerSearchResults());
            dispatch(setGeneratedFacilityCode());
            goToSearchPage();
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
  selectFacilityLogo: (file) => {
    dispatch(change(addFacilityForm, "facilityImgFile", file));
  },
  clearFacilityLogo: () => {
    dispatch(change(addFacilityForm, "facilityImgFile", null));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  addFacility: (handleShowError) =>
    dispatchProps.addFacility(dispatchProps.goToSearchPage, handleShowError),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addFacilityForm,
    initialValues: {
      hideFacilityInfo: false,
      facilityCode: "",
      producer: "",
      facilityCommissionRate: "5",
      otherInsured: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
      contacts: [
        {
          fullName: "",
          role: "",
          email: "",
          copyOnEmails: false,
        },
      ],
      apsGroupId: DEFAULT_APS_GROUP_ID,
      apsPlanId: DEFAULT_APS_PLAN_ID,
      elixirGroupId: DEFAULT_ELIXIR_GROUP_ID,
      recuroGroupId: "",
      requiresRecuroSubscriberNumber: false,
      doNotSendEmailsToMember: false,
      monthlyPrice: "",
    },
    validate: (values) => ({ ...contactEmailsUnique(values) }),
  })
)(AddFacility);
