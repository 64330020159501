import { from, of, concat } from "rxjs";
import { switchMap, mergeMap, catchError, tap, delay } from "rxjs/operators";
import { Action, Reactable } from "@reactables/core";
import { Reducers, RxBuilder } from "@jauntin/reactables";
import MembershipService from "Services/MembershipService";
import { CancelMemberPayload } from "./Models/member.model";

const { loadableInitialState, load, loadSuccess, loadError } = Reducers;

export interface CancelMemberActions {
  cancelMember: (payload: CancelMemberPayload) => void;
  cancelMemberReset: (payload: CancelMemberPayload) => void;
}

export type CancelMemberState = Reducers.LoadableState<null>;

export const RxCancelMember = ({
  id,
  membershipService,
  onCancelMemberSuccess,
}: {
  id: number;
  membershipService: MembershipService;
  onCancelMemberSuccess?: () => void;
}): Reactable<CancelMemberState, CancelMemberActions> =>
  RxBuilder({
    name: "rxCancelMember",
    initialState: loadableInitialState,
    reducers: {
      cancelMember: {
        reducer: load,
        effects: [
          (action$) =>
            action$.pipe(
              switchMap(({ payload }: Action<CancelMemberPayload>) => {
                return from(membershipService.cancelMember(payload, id)).pipe(
                  tap(() => {
                    onCancelMemberSuccess && onCancelMemberSuccess();
                  }),
                  mergeMap(() =>
                    of(
                      { type: "cancelMemberSuccess" },
                      { type: "cancelMemberReset" }
                    )
                  ),
                  catchError(() =>
                    concat(
                      of({ type: "cancelMemberFailure", payload: true }),
                      of({ type: "cancelMemberReset" }).pipe(delay(3000))
                    )
                  )
                );
              })
            ),
        ],
      },
      cancelMemberSuccess: loadSuccess,
      cancelMemberFailure: loadError,
      cancelMemberReset: () => loadableInitialState,
    },
  });
