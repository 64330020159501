import { useCallback } from "react";
import { HookedReactable } from "@reactables/react";
import { Form, Field } from "@reactables/react-forms";
import { RequestState, RequestActions } from "@jauntin/reactables";
import { ExtendedMeta } from "@jauntin/utilities";
import { SelectList as DefaultSelectList, RadioInput } from "@jauntin/react-ui";
import { Row, Col } from "react-bootstrap";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import { planTypeOptions } from "@basicare/common/src/Constants/planTypes";
import { discountTypeOptions } from "@basicare/common/src/Constants/discountTypes";
import {
  promoCodeTypeOptions,
  PromoCodeTypes,
} from "@basicare/common/src/Constants/promoCodeTypes";
import { getLgSelectStyle } from "@basicare/common/src/Helpers/ReactSelectStyle";
import {
  PromoCodeFormState,
  PromoCodeFormActions,
} from "Features/PromoCodes/Rx/RxPromoCodeForm";
import StyledIntegerInput from "@basicare/common/src/Components/FormElements/IntegerNumberInput/StyledIntegerNumberInput";
import DiscountAmountField from "./DiscountAmountField";
import StyledDateRangePickerField from "@basicare/common/src/Components/Event/DateRangePickerField/StyledDateRangePickerField";
import { isBefore, startOfDay } from "date-fns";
import { PromoCodeDetails } from "@basicare/common/src/Models/promoCode.model";
import { BillingCycleTypes } from "@basicare/common/src/Constants/billingCycleTypes";
import PromoCodeReferral from "./PromoCodeReferral";

const PromoCodeForm = ({
  form,
  showErrors = ({ touched, error }: ExtendedMeta) => Boolean(touched && error),
  generatePromoCodeRequest,
  promoCodeDetails,
}: {
  form:
    | HookedReactable<PromoCodeFormState, PromoCodeFormActions>
    | [PromoCodeFormState, PromoCodeFormActions];
  showErrors?: (meta: ExtendedMeta) => boolean;
  generatePromoCodeRequest?: [RequestState<string>, RequestActions<undefined>];
  editMode?: boolean;
  promoCodeDetails?: PromoCodeDetails;
}) => {
  const editMode = Boolean(promoCodeDetails);

  const [formState, formActions] = form;

  const { validatorErrors, pending, value } = formState.code;

  const isPromoCodeValidating =
    value && Object.values(validatorErrors).every((error) => !error) && pending;

  const {
    dateRange: { startDate },
    type,
    billingCycles: { type: billingCyclesType },
    code,
  } = formState.root.value;

  const disabledDates = useCallback(
    (focusedInput) => (day) => {
      return focusedInput === "endDate"
        ? isBefore(day, new Date(startDate)) ||
            isBefore(day, startOfDay(new Date()))
        : false;
    },
    [startDate]
  );

  return (
    <Form rxForm={form}>
      <div className="content__body">
        <PromoCodeReferral
          referralLink={`${process.env.REACT_APP_FRONTEND_LOCATION}?promo=${code}`}
        />
        <div className="card mb-4">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>General Information</strong>
            </div>
          </div>
          <div className="card-body">
            <Row>
              <Col md={6}>
                <Field
                  name={`name`}
                  component={TextField}
                  label="Promo Code Name"
                  inputClassName="form-control-lg"
                  showErrors={showErrors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name={`code`}
                  component={TextField}
                  disabled={editMode}
                  errorMessages={{
                    minLength3: "Must be at least 3 characters",
                    promoCodeFormat: "The code format is invalid",
                    uniquePromoCode:
                      "This Promo Code ID has already been taken",
                  }}
                  label={
                    <>
                      Promo Code ID
                      {isPromoCodeValidating && (
                        <span className="ml-2 small">Validating...</span>
                      )}
                    </>
                  }
                  inputClassName="form-control-lg"
                  showErrors={showErrors}
                  components={{
                    inputAppend: (() => {
                      if (generatePromoCodeRequest) {
                        const [
                          { loading: generatingPromoCode },
                          { send: generatePromoCode },
                        ] = generatePromoCodeRequest;
                        return (
                          <AsyncButton
                            outline
                            className="border-left-radius-0"
                            spinning={generatingPromoCode}
                            onClick={generatePromoCode}
                          >
                            Generate
                          </AsyncButton>
                        );
                      }

                      return null;
                    })(),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  component={SelectList}
                  name="type"
                  label="Promo Code Type"
                  disabled={editMode}
                  options={promoCodeTypeOptions}
                  customStyles={getLgSelectStyle}
                  onChange={formActions.selectPromoCodeType}
                  showErrors={showErrors}
                />
              </Col>
              <Col md={6}>
                <Field
                  component={SelectList}
                  name="subscriptionInterval"
                  label="Subscription Interval"
                  options={planTypeOptions}
                  customStyles={getLgSelectStyle}
                  disabled={
                    type === PromoCodeTypes.Trial ||
                    type === PromoCodeTypes.TrialPurchase ||
                    editMode
                  }
                  showErrors={showErrors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name={`discountAmount`}
                  component={DiscountAmountField}
                  rootControl={formState.root}
                  discountAmountControl={formState.discountAmount}
                  label="Discount Amount"
                  inputClassName="form-control-lg text-center"
                  showErrors={showErrors}
                  disabled={
                    type === PromoCodeTypes.Trial ||
                    type === PromoCodeTypes.TrialPurchase ||
                    editMode
                  }
                  components={{
                    inputAppend: (
                      <Field
                        name="discountType"
                        component={DefaultSelectList}
                        searchable={false}
                        options={discountTypeOptions}
                        customStyles={getLgSelectStyle(false, true)}
                        onChange={formActions.selectDiscountType}
                        disabled={
                          type === PromoCodeTypes.Trial ||
                          type === PromoCodeTypes.TrialPurchase ||
                          editMode
                        }
                      />
                    ),
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="label">Billing Cycles</div>
                <div className="pl-4 mb-3">
                  <Field
                    name={`billingCycles.type`}
                    className="radio-with-label-input"
                    component={RadioInput}
                    disabled={editMode}
                    label={
                      <div className="d-flex align-items-center gap-3 mb-2">
                        <span>For</span>
                        {billingCyclesType === BillingCycleTypes.Limited ? (
                          <Field
                            name="billingCycles.count"
                            component={StyledIntegerInput}
                            className="mb-0 w--70"
                            disabled={editMode}
                            min={0}
                            showErrors={showErrors}
                          />
                        ) : (
                          <input
                            className="form-control form-control-lg w--70"
                            type="number"
                            disabled
                          />
                        )}
                        <span>billing cycles</span>
                      </div>
                    }
                    value={BillingCycleTypes.Limited}
                    onChange={() =>
                      formActions.selectBillingCycleType(
                        BillingCycleTypes.Limited
                      )
                    }
                    checked={billingCyclesType === BillingCycleTypes.Limited}
                  />
                  <Field
                    name={`billingCycles.type`}
                    component={RadioInput}
                    disabled={type === PromoCodeTypes.TrialPurchase || editMode}
                    label="Unlimited"
                    value={BillingCycleTypes.Unlimited}
                    onChange={() =>
                      formActions.selectBillingCycleType(
                        BillingCycleTypes.Unlimited
                      )
                    }
                    checked={billingCyclesType === BillingCycleTypes.Unlimited}
                  />
                </div>
              </Col>
              <Col md={6}>
                <Field
                  name="usages"
                  component={StyledIntegerInput}
                  inputClassName="form-control-lg"
                  min={0}
                  label="Number of Usages (leave blank if unlimited)"
                  errorMessages={{
                    usageGreaterThanRedeems: promoCodeDetails
                      ? `The entered value is less than the Current Usage Count of ${promoCodeDetails?.redeems}.`
                      : "Error",
                  }}
                  showErrors={showErrors}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  component={StyledDateRangePickerField}
                  name="dateRange"
                  inputClassName="form-control-lg"
                  disabledDates={disabledDates}
                  startDateDisabled={editMode}
                  startDateLabel="Start Date"
                  endDateLabel="End Date (included; leave blank if unlimited)"
                  showStartDateErrors={showErrors}
                  showErrors={() => false}
                  showEndDateErrors={() => false}
                  placement="top"
                  errorMessages={{
                    startDateRequired: "Required",
                  }}
                  components={{
                    endDateInputAppend: (
                      <>
                        {formState.root.value.dateRange.endDate && (
                          <button
                            className="undecorate-btn position-absolute"
                            style={{ top: 7, right: 5, zIndex: 5 }}
                            onClick={formActions.clearEndDate}
                          >
                            <i className="fa fa-times" />
                          </button>
                        )}
                      </>
                    ),
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PromoCodeForm;
